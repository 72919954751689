import { ClassNames, keyframes } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import React, { ReactElement, useCallback, useMemo } from 'react'

export interface TipAnimationProps {
    show?: boolean
    iterationCount?: string
    duration?: number
    children: ReactElement<any, any>
}

const TipAnimation: React.FC<TipAnimationProps> = ({
    show = false,
    iterationCount = '1',
    duration = 1,
    children
}) => {
    const prefersReducedMotion = useMediaQuery(
        '(prefers-reduced-motion: reduce)'
    )

    const tipAnimation = keyframes({
        '0%': { transform: 'rotate(0deg)' },
        '40%': { transform: 'rotate(-20deg)' },
        '80%': { transform: 'rotate(-20deg)' },
        '100%': { transform: 'rotate(0deg)' }
    })

    const tipAnimationStyles = useMemo(() => {
        return {
            overflow: 'visible',
            animation: tipAnimation,
            animationName: tipAnimation.name,
            animationDirection: 'normal',
            animationDuration: `${duration}s`,
            animationTimingFunction: 'ease-in',
            animationIterationCount: iterationCount,
            animationPlayState:
                show && !prefersReducedMotion ? 'running' : 'paused',
            transformOrigin: 'bottom'
        }
    }, [tipAnimation, duration, iterationCount, show, prefersReducedMotion])

    const renderChildElement = useCallback(
        (css, cx) => {
            return React.cloneElement(children, {
                className: cx(css(tipAnimationStyles))
            })
        },
        [children, tipAnimationStyles]
    )

    return (
        <ClassNames>{({ css, cx }) => renderChildElement(css, cx)}</ClassNames>
    )
}

export default TipAnimation
