import { Box, Button, Card, CardActionArea, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import WateringCan from '../../../../shared/components/icons/plants/wateringCan'
import {
    HiddenDescription,
    JiggleAnimation
} from '../../../components/elements'
import { AchievementAccessibilityDescriptions } from '../../../enums'
import {
    useAchievementAccessibilityDescriptions,
    usePlantsContext,
    useWaterContext
} from '../../../hooks'

const messages = defineMessages({
    waterPlant: {
        defaultMessage: 'water {plantName} plant',
        description: 'description for water the plant button'
    }
})

export type WateringCanCardProps = {
    full: boolean
    isLarge?: boolean
    disabled?: boolean
    plantName?: string
    onWater?: () => void
}

const WateringCanCard: React.FC<WateringCanCardProps> = ({
    full,
    isLarge = true,
    disabled,
    plantName,
    onWater
}) => {
    const { formatMessage } = useIntl()
    const descriptions = useAchievementAccessibilityDescriptions()
    const { activePlants } = usePlantsContext()

    const {
        showEmptyingAnimation,
        showRefillAnimation,
        showJiggleAnimation,
        setShowEmptyingAnimation,
        setShowRefillAnimation,
        setShowJiggleAnimation
    } = useWaterContext()

    const onNoOpWater = useCallback(() => {
        setShowJiggleAnimation(true)
    }, [setShowJiggleAnimation])

    const buttonDisabled = useMemo(() => {
        return !full || disabled || activePlants?.length === 0
    }, [full, disabled, activePlants])

    const withActionArea = useCallback(
        (content) =>
            isLarge ? (
                content
            ) : (
                <CardActionArea
                    disabled={disabled}
                    onClick={onWater}
                    aria-label={formatMessage(messages.waterPlant, {
                        plantName
                    })}
                >
                    {content}
                </CardActionArea>
            ),
        [isLarge, disabled, plantName, formatMessage, onWater]
    )

    return (
        <Box
            position="relative"
            data-testid="watering-can-card"
            overflow="visible"
            width={'100%'}
            maxWidth={isLarge ? 165 : 110}
            minWidth={isLarge ? 165 : 110}
            {...(!full && {
                onClick: onNoOpWater
            })}
        >
            <HiddenDescription
                id="watering-can-description"
                component="output"
                aria-live="polite"
                aria-atomic="true"
            >
                {descriptions[AchievementAccessibilityDescriptions.WaterStatus](
                    {
                        canWater: full
                    }
                )}
            </HiddenDescription>
            <Card elevation={1}>
                {withActionArea(
                    <Stack flexDirection="column" gap={1} p={2}>
                        <CoreTypography
                            variant="overline"
                            textAlign="left"
                            component="h2"
                        >
                            <FormattedMessage
                                defaultMessage="Water"
                                description="title name for the watering can card"
                            />
                        </CoreTypography>
                        <Stack gap={2}>
                            <JiggleAnimation
                                show={showJiggleAnimation}
                                onAnimationComplete={() =>
                                    setShowJiggleAnimation(false)
                                }
                            >
                                <WateringCan
                                    full={full}
                                    watering={showEmptyingAnimation}
                                    refill={showRefillAnimation}
                                    onWateringAnimationComplete={() => {
                                        setShowEmptyingAnimation(false)
                                        setShowRefillAnimation(false)
                                    }}
                                    sx={{ overflow: 'visible' }}
                                />
                            </JiggleAnimation>
                            {isLarge && (
                                <Button
                                    disabled={buttonDisabled}
                                    variant="outlined"
                                    onClick={onWater}
                                    aria-label={formatMessage(
                                        messages.waterPlant,
                                        { plantName }
                                    )}
                                    sx={{ pointerEvents: 'initial' }}
                                >
                                    <CoreTypography customVariant="buttonLarge">
                                        <FormattedMessage
                                            defaultMessage="Water plant"
                                            description="title name for the watering can card"
                                        />
                                    </CoreTypography>
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                )}
            </Card>
        </Box>
    )
}

export default memo(WateringCanCard)
