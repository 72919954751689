import { useContext } from 'react'
import WaterProviderContext, {
    WaterProviderValue
} from './waterProviderContext'

export const useWaterContext = (): WaterProviderValue => {
    const context = useContext(WaterProviderContext)

    if (context === undefined) {
        throw new Error('useWaterContext must be used within a WaterContext')
    }

    return context
}
