import { Card, CardActionArea, Icon, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { Suspense, memo, useCallback, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { PlantCircularProgress } from '..'
import { AchievementV2 } from '../../../../graphql/generated/autogenerated'
import { AchievementAccessibilityDescriptions } from '../../../../shared/enums/achievementAccessibilityDescriptions'
import { AchievementIcons } from '../../../enums/achievementIcons'
import useAchievementAccessibilityDescriptions from '../../../../shared/hooks/useAchievementAccessibilityDescriptions/useAchievementAccessibilityDescriptions'
import { AchievementStates } from '../../../enums/achievementStates'
import {
    UserProgressDetail,
    useAchievementIcons,
    useAchievementLinks,
    useAchievementState
} from '../../../hooks'
import ClaimCurrencyButton from '../ClaimCurrencyButton/ClaimCurrencyButton'

const messages = defineMessages({
    level: {
        defaultMessage: 'Level {level}',
        description: 'descriptions of the achievement level'
    },
    claim: {
        defaultMessage: 'Claim',
        description: 'buttons description for the claim button'
    },
    claimAll: {
        defaultMessage: 'Claim all',
        description: 'buttons description for the claim all button'
    },
    claimAllAria: {
        defaultMessage: 'Claim all {achievementName} levels',
        description:
            'description of what level and achievement is being claimed'
    },
    claimAria: {
        defaultMessage: 'Claim {achievementName}, level {level}',
        description:
            'description of what level and achievement is being claimed'
    }
})

export type ClaimAchievementCardProps = {
    achievement: AchievementV2
    userProgressDetail: UserProgressDetail
    claimableLevels?: number[]
    claimAllText?: boolean
    disableClaim?: boolean
    allowFocus?: boolean
    onClaimStart?: () => void
    onClaimComplete?: () => void
}

const ClaimAchievementCard: React.FC<ClaimAchievementCardProps> = ({
    achievement,
    userProgressDetail,
    claimableLevels,
    claimAllText,
    disableClaim,
    allowFocus,
    onClaimStart,
    onClaimComplete
}) => {
    const { spacing } = useTheme()
    const { formatMessage } = useIntl()
    const achievementState = useAchievementState(userProgressDetail)
    const getAchievementIcon = useAchievementIcons()
    const getAchievementLink = useAchievementLinks()
    const descriptions = useAchievementAccessibilityDescriptions()

    const { link, label } = useMemo(
        () => getAchievementLink(achievement),
        [getAchievementLink, achievement]
    )

    const achievementLevel = useMemo(() => {
        if (achievement.upc === AchievementIcons.communitybuilder) {
            return userProgressDetail.level + 1
        }

        return userProgressDetail.level
    }, [achievement, userProgressDetail])

    const { totalSteps, completedSteps } = useMemo(() => {
        return {
            totalSteps:
                achievement.upc === AchievementIcons.communitybuilder
                    ? 1
                    : userProgressDetail.total,
            completedSteps:
                achievement.upc === AchievementIcons.communitybuilder
                    ? 0
                    : userProgressDetail.currentProgress
        }
    }, [userProgressDetail, achievement.upc])

    const actionsDisabled = useMemo(() => {
        return disableClaim || achievementState === AchievementStates.locked
    }, [disableClaim, achievementState])

    const actionableCard = useCallback(
        (content: React.ReactNode) =>
            link ? (
                <CardActionArea
                    onClick={link}
                    aria-label={label}
                    disableRipple={
                        !link ||
                        disableClaim ||
                        (link && achievementState === AchievementStates.locked)
                    }
                    {...(link &&
                        !actionsDisabled && {
                            sx: {
                                '.MuiCardActionArea-focusHighlight': {
                                    background: 'transparent',
                                    pointerEvents: 'none'
                                }
                            }
                        })}
                    {...((!link || disableClaim) && {
                        sx: {
                            cursor: 'default',
                            '.MuiCardActionArea-focusHighlight': {
                                background: 'transparent',
                                pointerEvents: 'none'
                            }
                        }
                    })}
                >
                    {content}
                </CardActionArea>
            ) : (
                content
            ),
        [link, label, disableClaim, actionsDisabled, achievementState]
    )

    return (
        <Card
            elevation={8}
            data-testid="claim-achievement-card"
            sx={{ width: '100%', height: '100%' }}
        >
            {actionableCard(
                <Stack pb={2}>
                    <Stack
                        direction="row"
                        height="100%"
                        alignItems="center"
                        gap={2}
                        pr={2}
                        justifyContent="space-between"
                    >
                        <Stack
                            p={spacing(2, 0, 2, 2)}
                            direction="row"
                            alignItems="center"
                            gap={2}
                        >
                            <Suspense fallback={<></>}>
                                <Icon
                                    titleAccess={achievement.title}
                                    component={getAchievementIcon(achievement)}
                                    sx={{
                                        maxHeight: spacing(8),
                                        maxWidth: spacing(7),
                                        minHeight: spacing(8),
                                        minWidth: spacing(7)
                                    }}
                                />
                            </Suspense>
                            <Stack
                                sx={{
                                    width: '100%'
                                }}
                            >
                                <CoreTypography variant="overline">
                                    {achievement.title}
                                </CoreTypography>
                                {achievementLevel > 0 && (
                                    <CoreTypography
                                        variant="overline"
                                        color="text.primary"
                                    >
                                        <FormattedMessage
                                            defaultMessage="Level {level}"
                                            description="descriptions of the achievement level"
                                            values={{ level: achievementLevel }}
                                        />
                                    </CoreTypography>
                                )}
                            </Stack>
                        </Stack>
                        {(achievementState === AchievementStates.locked ||
                            disableClaim) && (
                            <PlantCircularProgress
                                completedSteps={completedSteps}
                                totalSteps={totalSteps}
                                isLarge={true}
                                ariaLabel={descriptions[
                                    AchievementAccessibilityDescriptions
                                        .AchievementLevel
                                ]({
                                    achievementName: achievement.title,
                                    totalSteps: userProgressDetail.total,
                                    steps: userProgressDetail.currentProgress,
                                    level: achievementLevel
                                })}
                            />
                        )}
                        {!actionsDisabled && (
                            <Stack sx={{ pointerEvents: 'initial' }}>
                                <ClaimCurrencyButton
                                    userProgressDetail={userProgressDetail}
                                    allowFocus={allowFocus}
                                    levels={
                                        claimableLevels?.length < 1
                                            ? [achievementLevel]
                                            : claimableLevels
                                    }
                                    text={formatMessage(
                                        claimAllText &&
                                            claimableLevels?.length > 1
                                            ? messages.claimAll
                                            : messages.claim
                                    )}
                                    ariaLabel={formatMessage(
                                        claimAllText &&
                                            claimableLevels?.length > 1
                                            ? messages.claimAllAria
                                            : messages.claimAria,
                                        {
                                            achievementName: achievement.title,
                                            level: achievementLevel
                                        }
                                    )}
                                    onClaimStart={() => onClaimStart?.()}
                                    onClaimComplete={() => onClaimComplete?.()}
                                />
                            </Stack>
                        )}
                    </Stack>
                    <CoreTypography variant="body1" px={2}>
                        {achievement.description}
                    </CoreTypography>
                </Stack>
            )}
        </Card>
    )
}

export default memo(ClaimAchievementCard)
