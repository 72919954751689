import { Box } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useRef } from 'react'
import { useActiveRefDimensions } from '../../../hooks'

export type AbsolutelyPositionedProps = {
    ignoreWidth?: boolean
    ignoreHeight?: boolean
    top?: string
    right?: string
    bottom?: string
    left?: string
    children: React.ReactNode
}

const AbsolutelyPositioned: React.FC<AbsolutelyPositionedProps> = ({
    ignoreWidth,
    ignoreHeight,
    top,
    right,
    left,
    bottom,
    children
}) => {
    const theme = useTheme()

    const { activeComponentWidth, activeComponentHeight, getRef } =
        useActiveRefDimensions()

    return (
        <Box
            {...(!ignoreHeight && {
                minHeight: theme.spacing(activeComponentHeight)
            })}
            {...(!ignoreWidth && {
                minWidth: theme.spacing(activeComponentWidth)
            })}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="absolute"
                {...(top && { top: top })}
                {...(right && { right: right })}
                {...(bottom && { bottom: bottom })}
                {...(left && { left: left })}
                ref={(element) => getRef(element)}
            >
                {children}
            </Box>
        </Box>
    )
}

export default React.memo(AbsolutelyPositioned)
