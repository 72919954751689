import { Card, Stack } from '@mui/material'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import parseISO from 'date-fns/parseISO'
import { useMemo } from 'react'
import { PlantType } from '../../../../enums/plantTypes'
import { ReactNullValue } from '../../../../../utils/Nulls'
import { usePlantsContext } from '../../../../hooks'
import withPlantsProvider from '../../../../hooks/usePlantsProvider/withPlantsProvider'
import ResetEmbeddedPlant from './ResetEmbeddedPlant'
import ResetEmbeddedPlantSelector from './ResetEmbeddedPlantSelector'

const ResetEmbeddedGarden = () => {
    const { activePlants, plantedPlants } = usePlantsContext()

    const shouldShowSelector = useMemo(() => {
        const plantedExcludingOnboarding = plantedPlants.filter(
            (p) => p.productUpc !== PlantType.novafutura
        )
        return (
            !activePlants[0]?.progressSteps &&
            plantedExcludingOnboarding.length === 0
        )
    }, [activePlants, plantedPlants])

    const shouldShowPlantGrowth = useMemo(() => {
        if (!activePlants[0] || activePlants[0].progressSteps !== 1) {
            return false
        }
        const secondsSinceLastWatered = differenceInSeconds(
            new Date(),
            parseISO(activePlants[0].wateredAt)
        )

        // If the plant has been watered within the last 15 seconds, show the plant growth
        return secondsSinceLastWatered <= 15
    }, [activePlants])

    if (!shouldShowSelector && !shouldShowPlantGrowth) {
        return ReactNullValue
    }

    return (
        <Card>
            <Stack
                maxWidth={512}
                padding={2}
                alignItems="center"
                justifyContent="center"
            >
                {shouldShowSelector ? (
                    <ResetEmbeddedPlantSelector />
                ) : (
                    <ResetEmbeddedPlant />
                )}
            </Stack>
        </Card>
    )
}

export default withPlantsProvider(ResetEmbeddedGarden)
