import { createContext } from 'react'

export interface WaterProviderValue {
    watering: boolean
    showEmptyingAnimation: boolean
    showRefillAnimation: boolean
    showJiggleAnimation: boolean
    setShowEmptyingAnimation: (active: boolean) => void
    setWatering: (watering: boolean) => void
    setShowRefillAnimation: (active: boolean) => void
    setShowJiggleAnimation: (show: boolean) => void
}

const defaultContextValue: WaterProviderValue = {
    watering: false,
    showEmptyingAnimation: false,
    showRefillAnimation: false,
    showJiggleAnimation: false,
    setWatering: () => {},
    setShowEmptyingAnimation: () => {},
    setShowRefillAnimation: () => {},
    setShowJiggleAnimation: () => {}
}

export default createContext(defaultContextValue)
