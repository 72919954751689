import { Box, Button, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { UserPlantInfo } from '../../../../../../graphql/generated/autogenerated'
import { ROUTES } from '../../../../../../routes'
import { usePlantsContext, useWaterContext } from '../../../../../hooks'
import { plantCardMessages } from '../../PlantCard/PlantCard'

const messages = defineMessages({
    plant: {
        defaultMessage: 'Plant plant',
        description: 'button title for planting a plant'
    },
    water: {
        defaultMessage: 'Water plant',
        description: 'button title for watering a plant'
    },
    waterAria: {
        defaultMessage: 'Water {plantName} plant',
        description: 'button title for watering a plant'
    }
})

export type GardenActionButtonProps = {
    plants: UserPlantInfo[]
    myPlant: UserPlantInfo
    plantName?: string
    loading: boolean
    isLarge?: boolean
    isWidget?: boolean
    isAchievementCenter?: boolean
    disabled: boolean
    hasActivePlant: boolean
    hasFullyWateredPlant: boolean
    onWaterPlant?: (plant: UserPlantInfo) => Promise<void>
    onPlantPlant?: (plant: UserPlantInfo) => Promise<void>
}

export type PromiseResult<T> = T extends Promise<infer TResult> ? TResult : T

const GardenActionButton: React.FC<GardenActionButtonProps> = ({
    plants,
    myPlant,
    plantName,
    loading,
    isLarge,
    isWidget,
    isAchievementCenter,
    disabled,
    hasActivePlant,
    hasFullyWateredPlant,
    onWaterPlant,
    onPlantPlant
}) => {
    const theme = useTheme()
    const history = useHistory()
    const { setShowJiggleAnimation } = useWaterContext()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { formatMessage } = useIntl()
    const { plantedPlants } = usePlantsContext()

    const { onClick, title, label } = useMemo(() => {
        if (hasFullyWateredPlant) {
            return {
                onClick: () => onPlantPlant(myPlant),
                title: formatMessage(messages.plant)
            }
        } else if (hasActivePlant) {
            return {
                onClick: () => onWaterPlant(myPlant),
                title: formatMessage(messages.water),
                label: formatMessage(messages.waterAria, { plantName })
            }
        } else {
            return {
                onClick: () => history.push(ROUTES.SHOP),
                title: formatMessage(
                    plantedPlants?.length > 0
                        ? plantCardMessages.growAnotherPlant
                        : plantCardMessages.growAPlant
                )
            }
        }
    }, [
        formatMessage,
        history,
        plantedPlants,
        plantName,
        onPlantPlant,
        onWaterPlant,
        hasActivePlant,
        hasFullyWateredPlant,
        myPlant
    ])

    const handleDisabledClickEvent = useCallback(() => {
        const hasActivePlant = Boolean(plants?.length > 0)

        if (disabled && !loading && hasActivePlant) {
            setShowJiggleAnimation(true)
        }
    }, [disabled, loading, plants, setShowJiggleAnimation])

    return (
        <Box
            {...(disabled && {
                onClick: handleDisabledClickEvent
            })}
        >
            <Stack
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={2}
            >
                {plants?.length === 0 && (isLarge || isWidget) ? (
                    <Stack gap={2} alignItems="center">
                        <CoreTypography
                            variant={isLarge ? 'h5' : 'body1'}
                            textAlign="center"
                        >
                            {plantedPlants?.length > 0
                                ? formatMessage(plantCardMessages.nextTimeEmpty)
                                : formatMessage(
                                      plantCardMessages.firstTimeEmpty
                                  )}
                        </CoreTypography>
                        <Box>
                            <Button
                                variant="outlined"
                                onClick={() => history.push(ROUTES.SHOP)}
                            >
                                <CoreTypography
                                    customVariant={
                                        isLarge ? 'buttonLarge' : 'buttonNormal'
                                    }
                                >
                                    {plantedPlants?.length > 0
                                        ? formatMessage(
                                              plantCardMessages.growAnotherPlant
                                          )
                                        : formatMessage(
                                              plantCardMessages.growAPlant
                                          )}
                                </CoreTypography>
                            </Button>
                        </Box>
                    </Stack>
                ) : isAchievementCenter || isWidget || isMobile ? (
                    <LoadingButton
                        variant="outlined"
                        onClick={onClick}
                        data-testid="garden-action-button"
                        disabled={hasActivePlant && (disabled || loading)}
                        loading={loading}
                        aria-label={label}
                        fixWidth
                    >
                        <CoreTypography customVariant="buttonLarge">
                            {title}
                        </CoreTypography>
                    </LoadingButton>
                ) : undefined}
            </Stack>
        </Box>
    )
}

export default React.memo(GardenActionButton)
