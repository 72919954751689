import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { CurrentAchievementProgress } from '../useAchievementProgressions/useAchievementProgressions'
import { AchievementIcons } from '../../shared/enums'
import { isJourneyRelatedAchievement } from '../../utils'

const messages = defineMessages({
    achievementTitle: {
        defaultMessage: '{achievementTitle} Achievement',
        description: 'achievement title'
    },
    journeysAchievementTitle: {
        defaultMessage: 'Journeys Achievement',
        description: 'journey achievement title'
    },
    communityBuilderDescription: {
        defaultMessage:
            'Invite people to Thrive, and level up this achievement.',
        description: 'community builder description'
    },
    communityBuilderButton: {
        defaultMessage: 'Refer a coworker',
        description: 'community builder button'
    },
    microstepperDescription: {
        defaultMessage:
            'Make progress towards this Achievement each time you take action on a Microstep!',
        description: 'microstepper description'
    },
    microstepperButton: {
        defaultMessage: 'Complete Microsteps',
        description: 'microstepper button'
    },
    mindfulMomentsDescription: {
        defaultMessage:
            'Make progress towards this Achievement each time you beat cumulative stress by watching a Reset.',
        description: 'mindful moments description'
    },
    mindfulMomentsButton: {
        defaultMessage: 'View Resets',
        description: 'mindful moments button'
    },
    dailyReflectionDescription: {
        defaultMessage:
            'Make progress towards this Achievement each day, when you reflect and answer your Daily Check-In question.',
        description: 'daily reflection description'
    },
    dailyReflectionButton: {
        defaultMessage: 'View Daily Check-In',
        description: 'daily reflection button'
    },
    journeysDescription: {
        defaultMessage:
            'Claim this Achievement each time you complete a level of your Journey.',
        description: 'journeys description'
    },
    journeysButton: {
        defaultMessage: 'View Journey',
        description: 'journeys button'
    }
})

export const useGetAchievementDialogInfo = (
    currentAchievementProgress: CurrentAchievementProgress
) => {
    const { formatMessage } = useIntl()
    const isJourneyAchievement = useMemo(
        () => isJourneyRelatedAchievement(currentAchievementProgress.upc),
        [currentAchievementProgress.upc]
    )

    const { description, buttonText, buttonRoute, title } = useMemo(() => {
        let description = undefined
        let buttonText = undefined
        let buttonRoute = undefined
        let title = formatMessage(messages.achievementTitle, {
            achievementTitle: currentAchievementProgress.title
        })
        switch (currentAchievementProgress.upc) {
            case AchievementIcons.communitybuilder:
                description = formatMessage(
                    messages.communityBuilderDescription
                )
                buttonText = formatMessage(messages.communityBuilderButton)
                buttonRoute = '/profile/invite'
                break
            case AchievementIcons.microstepper:
                description = formatMessage(messages.microstepperDescription)
                buttonText = formatMessage(messages.microstepperButton)
                buttonRoute = '/today/microsteps'
                break
            case AchievementIcons.mindfulMoments:
                description = formatMessage(messages.mindfulMomentsDescription)
                buttonText = formatMessage(messages.mindfulMomentsButton)
                buttonRoute = '/reset'
                break
            case AchievementIcons.dailyReflection:
                description = formatMessage(messages.dailyReflectionDescription)
                buttonText = formatMessage(messages.dailyReflectionButton)
                buttonRoute = '/journeys'
                break
            case AchievementIcons.recharge:
            case AchievementIcons.focus:
            case AchievementIcons.connect:
            case AchievementIcons.money:
            case AchievementIcons.move:
            case AchievementIcons.food:
            case AchievementIcons.stress:
                title = formatMessage(messages.journeysAchievementTitle)
                description = formatMessage(messages.journeysDescription)
                buttonText = formatMessage(messages.journeysButton)
                buttonRoute = '/journeys'
                break
            default:
                break
        }
        return {
            description,
            buttonText,
            buttonRoute,
            title
        }
    }, [currentAchievementProgress, formatMessage])

    return useMemo(() => {
        return {
            isJourneyAchievement,
            description,
            buttonText,
            buttonRoute,
            title
        }
    }, [isJourneyAchievement, description, buttonText, buttonRoute, title])
}
