import { createContext } from 'react'
import { AchievementV2 } from '../../../graphql/generated/autogenerated'
import { AchievementLevelInfo } from './withAchievementsProvider'
export interface UserProgressDetail {
    achievementId: string
    claimedAt: string
    completedAt: string
    level: number
    total: number
    currentProgress: number
    title: string
}

export interface MappedAchievement {
    achievementInfo: AchievementV2
    userProgress: UserProgressDetail[]
}

export interface AchievementsProviderValue {
    achievements: MappedAchievement[]
    claimedAchievements: AchievementLevelInfo[]
    manuallyClaimed: string[]
    setManuallyClaimed: React.Dispatch<React.SetStateAction<string[]>>
    fetchAchievements: () => void
}

export const defaultAchievementContextValue: AchievementsProviderValue = {
    achievements: [],
    claimedAchievements: [],
    manuallyClaimed: [],
    setManuallyClaimed: () => ({}),
    fetchAchievements: () => ({})
}

export default createContext(defaultAchievementContextValue)
