import { useMemo } from 'react'
import {
    ProductInfo,
    UserPlantInfo
} from '../../../graphql/generated/autogenerated'

/**
 * Custom hook that calculates the progress of a plant's watering steps based on the provided plant and it's product information.
 *
 * @param plant - The user's plant information.
 * @param product - The product information associated with the plant.
 * @returns An object containing the plant progress information.
 */
export const usePlantProgress = (
    plant?: UserPlantInfo,
    product?: ProductInfo
) => {
    return useMemo(() => {
        const samePlant = plant?.productUpc === product?.upc
        if (!plant || !product || !samePlant) {
            if (!samePlant) {
                console.error('Plant and product are not the same plant')
            }
            return {
                completedSteps: 0,
                totalStepsNeeded: 0,
                remainingSteps: 0,
                percentageCompleted: 0,
                isFullyGrown: false
            }
        }

        const completedSteps = plant.progressSteps
        const totalStepsNeeded = product.plantWateringSteps || 1
        const remainingSteps = Math.max(totalStepsNeeded - completedSteps, 0)
        const percentageCompleted = (completedSteps / totalStepsNeeded) * 100
        const isFullyGrown = percentageCompleted >= 100

        return {
            completedSteps,
            totalStepsNeeded,
            remainingSteps,
            percentageCompleted,
            isFullyGrown
        }
    }, [plant, product])
}
