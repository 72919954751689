import { Box, Link, Stack, useMediaQuery } from '@mui/material'
import { ProductInfo } from '@thriveglobal/thrive-web-core/dist/graphql/generated/autogenerated'
import {
    CoreAlert,
    CoreTypography,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { useCallback, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import PlantToggle from '../../../../../components/elements/PlantSelectToggle/PlantSelectToggle'
import ProductInfoCard from '../../../../../components/elements/ProductInfoCard/ProductInfoCard'
import { useGetRecommendedPlantsQuery } from '../../../../../graphql/generated/autogenerated'
import { ROUTES } from '../../../../../routes'
import { ReactNullValue } from '../../../../../utils/Nulls'
import { usePlantsContext } from '../../../../hooks'

const MESSAGES = defineMessages({
    growAPlant: {
        defaultMessage:
            'We hope you enjoyed that Reset. Keep going — as you continue on your well-being journey, you can grow plants for your Thrive garden. Choose a plant and watch it grow along with you.',
        description:
            'message to encourage user to grow a plant after completing a reset'
    },
    confirmedFirstPlant: {
        defaultMessage:
            "This {plantName} will look great in your garden. It's time to get growing — watch another Reset to water your new plant.",
        description: 'message to confirm the user has selected a plant'
    },
    error: {
        defaultMessage:
            'An error occurred while selecting your plant. Please visit the <ShopLink>shop</ShopLink> to try again.',
        description: 'Error message when selecting plant fails'
    }
})

const ResetEmbeddedPlantSelector = () => {
    const { breakpoints } = useTheme()
    const isSmallScreen = useMediaQuery(breakpoints.down('sm'))
    const { formatMessage } = useIntl()
    const { products, purchaseProduct } = usePlantsContext()
    const {
        data,
        error: fetchError,
        loading: fetchLoading
    } = useGetRecommendedPlantsQuery()
    const [selectedPlantUpc, setSelectedPlantUpc] =
        useState<ProductInfo['upc']>(undefined)
    const selectedPlant = useMemo(() => {
        return products.find((p) => p.upc === selectedPlantUpc)
    }, [products, selectedPlantUpc])
    const [submitted, setSubmitted] = useState(false)
    const [submitError, setSubmitError] = useState<boolean>(false)

    const options = useMemo(() => {
        const recommendedProducts = data?.retail.getRecommendedPlants
        if (
            recommendedProducts?.__typename === 'RecommendedPlantsResult' &&
            recommendedProducts.recommendedPlants.length > 0
        ) {
            return recommendedProducts.recommendedPlants
        }
        return []
    }, [data])

    const handleSelectPlant = useCallback(
        (_: unknown, upc: ProductInfo['upc']) => {
            setSelectedPlantUpc(upc)
        },
        []
    )

    const handleSubmit = useCallback(async () => {
        if (!selectedPlant) return
        try {
            await purchaseProduct(selectedPlant)
            setSubmitted(true)
        } catch (e: unknown) {
            setSubmitError(true)
        }
    }, [purchaseProduct, selectedPlant])

    if (fetchError || fetchLoading) {
        return ReactNullValue
    }

    return (
        <Stack gap={2}>
            <CoreTypography variant="body1" lineHeight={1.3}>
                {submitted
                    ? formatMessage(MESSAGES.confirmedFirstPlant, {
                          plantName: selectedPlant.title
                      })
                    : formatMessage(MESSAGES.growAPlant)}
            </CoreTypography>
            <Stack
                gap={3}
                alignItems="center"
                justifyContent="center"
                direction={isSmallScreen ? 'column' : 'row'}
            >
                {submitted ? (
                    <ProductInfoCard
                        productInfo={products.find(
                            (p) => p.upc === selectedPlantUpc
                        )}
                    />
                ) : submitError ? (
                    <CoreAlert severity="error">
                        <Box>
                            {formatMessage(MESSAGES.error, {
                                ShopLink: (chunk) => (
                                    <span>
                                        <Link href={ROUTES.SHOP}>{chunk}</Link>
                                    </span>
                                )
                            })}
                        </Box>
                    </CoreAlert>
                ) : (
                    !!options.length && (
                        <PlantToggle
                            size={isSmallScreen ? 'small' : 'medium'}
                            options={[options[0], options[1]]}
                            onChange={handleSelectPlant}
                            value={selectedPlantUpc}
                            onSubmit={handleSubmit}
                        />
                    )
                )}
            </Stack>
        </Stack>
    )
}

export default ResetEmbeddedPlantSelector
