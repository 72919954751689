import { Stack } from '@mui/material'
import { format } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import ThriveTooltip from '../../../elements/ThriveTooltip/ThriveTooltip'

export interface PlantInfoTooltipProps {
    open: boolean
    setOpen: (open: boolean) => void
    challengeName?: string
    dateAdded: Date
    children?: ReactElement<any, any>
}

const PlantInfoTooltip: React.FC<PlantInfoTooltipProps> = ({
    open,
    setOpen,
    challengeName,
    dateAdded,
    children
}) => {
    return challengeName || dateAdded ? (
        <ThriveTooltip
            open={open}
            setOpen={setOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            data-testid="plant-info-tooltip"
            arrow
            content={
                <Stack gap={1} p={2}>
                    {dateAdded && (
                        <CoreTypography variant="body1" color="primary">
                            <FormattedMessage
                                defaultMessage="Added on {dateAdded}"
                                description="description on when a plant was added"
                                values={{
                                    dateAdded: format(new Date(dateAdded))
                                }}
                            />
                        </CoreTypography>
                    )}
                    {challengeName && (
                        <CoreTypography variant="body1" color="primary">
                            {challengeName}
                        </CoreTypography>
                    )}
                </Stack>
            }
        >
            {children}
        </ThriveTooltip>
    ) : (
        children
    )
}

export default memo(PlantInfoTooltip)
