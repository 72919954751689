import { Button, DialogActions, DialogContent, Stack } from '@mui/material'
import { CoreTypography, LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Image } from '../../../../shared'
import AchievementIcon from '../../../../shared/components/icons/achievementsV2/AchievementIcon'
import { AchievementIcons } from '../../../../shared/enums'

export type AchievementInfoProps = {
    open: boolean
    upc: string
    title: string
    description: string
    buttonText: string
    buttonRoute: string
    imageUrl?: string
    onClose: () => void
}

const AchievementInfo: React.FC<AchievementInfoProps> = ({
    upc,
    open,
    title,
    description,
    buttonText,
    buttonRoute,
    imageUrl,
    onClose
}) => {
    const history = useHistory()
    const onCtaClick = useCallback(() => {
        onClose()
        history.push(buttonRoute)
    }, [history, buttonRoute, onClose])
    return (
        <LeafDialog open={open} onClose={onClose} fullWidth>
            <Stack gap={3}>
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    gap={3}
                    pb={1}
                >
                    {imageUrl ? (
                        <Image src={imageUrl} height={160} width={160} />
                    ) : (
                        <AchievementIcon
                            size="large"
                            achievementIcon={upc as AchievementIcons}
                        />
                    )}
                    <Stack gap={1} alignItems="center" justifyContent="center">
                        <CoreTypography variant="h3">{title}</CoreTypography>
                        <CoreTypography variant="body1" textAlign="center">
                            {description}
                        </CoreTypography>
                    </Stack>
                </Stack>
                <DialogActions>
                    <Stack gap={1} direction="row">
                        <Button
                            onClick={onClose}
                            variant="contained"
                            color="secondary"
                            size="large"
                        >
                            <CoreTypography customVariant="buttonLarge">
                                <FormattedMessage
                                    defaultMessage="Close"
                                    description="close button"
                                />
                            </CoreTypography>
                        </Button>
                        <Button
                            onClick={onCtaClick}
                            variant="contained"
                            size="large"
                        >
                            <CoreTypography customVariant="buttonLarge">
                                {buttonText}
                            </CoreTypography>
                        </Button>
                    </Stack>
                </DialogActions>
            </Stack>
        </LeafDialog>
    )
}

export default AchievementInfo
