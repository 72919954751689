import { Box, Button, Card, Stack } from '@mui/material'
import {
    BurstNotification,
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import debounce from 'lodash/debounce'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useCountUp } from 'react-countup'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../../routes'
import { AchievementAccessibilityDescriptions } from '../../../enums'
import {
    useAchievementAccessibilityDescriptions,
    useActiveRefDimensions,
    useClaimTokens
} from '../../../hooks'
import { HiddenDescription } from '../../elements'

export type TokensCardProps = {
    isLarge?: boolean
    hideShopLink?: boolean
}

const countKey = 'TOKEN'

const TokensCard: React.FC<TokensCardProps> = ({ isLarge, hideShopLink }) => {
    const theme = useTheme()
    const { formatNumber } = useIntl()
    const descriptions = useAchievementAccessibilityDescriptions()
    const { tokenCount } = useClaimTokens()
    const [initialTokenCount, _] = useState(tokenCount)
    const [priorTokenCount, setPriorTokenCount] = useState(tokenCount)
    const [showBurst, setShowBurst] = useState(false)
    const { activeComponentWidth, activeComponentHeight, getRef } =
        useActiveRefDimensions(countKey)
    const history = useHistory()

    const countUpRef = React.useRef(null)

    const debouncedBurst = debounce(() => {
        setShowBurst(true)
    }, 1332)

    const { update } = useCountUp({
        ref: countUpRef,
        start: initialTokenCount,
        end: tokenCount,
        delay: 0.666,
        duration: 0.666,
        formattingFn: useCallback(
            (value: any) => {
                return formatNumber(value, {
                    maximumSignificantDigits: value > 1000 ? 2 : 3,
                    notation: 'compact',
                    compactDisplay: 'short'
                })
            },
            [formatNumber]
        ),
        onUpdate: useCallback(() => {
            if (priorTokenCount < tokenCount && !showBurst) {
                debouncedBurst()
            }
        }, [debouncedBurst, priorTokenCount, showBurst, tokenCount])
    })

    useEffect(() => {
        setShowBurst(false)
        update(tokenCount)
        if (tokenCount !== priorTokenCount) {
            setPriorTokenCount(tokenCount)
        }
    }, [setShowBurst, update, tokenCount, priorTokenCount])

    const BurstWrapper = useMemo(
        () => (showBurst ? BurstNotification : Box),
        [showBurst]
    )

    const handleRouteToShop = useCallback(() => {
        history.push(ROUTES.SHOP)
    }, [history])

    return (
        <Box
            width={'100%'}
            data-testid="tokenCount-card"
            maxWidth={isLarge ? 165 : 110}
            minWidth={isLarge ? 165 : 110}
        >
            <HiddenDescription
                id="tokenCount-card-description"
                component="output"
                aria-live="polite"
                aria-atomic="true"
            >
                {descriptions[AchievementAccessibilityDescriptions.TokenCount]({
                    tokenCount
                })}
            </HiddenDescription>
            <Card elevation={1} sx={{ p: theme.spacing(2, 2, 1.5, 1) }}>
                <Stack minWidth={theme.spacing(activeComponentWidth)}>
                    <CoreTypography variant="overline" pl={1} component="h2">
                        <FormattedMessage
                            defaultMessage="Tokens"
                            description="title name for the token currency"
                        />
                    </CoreTypography>
                    <Box
                        minHeight={theme.spacing(
                            activeComponentHeight ? activeComponentHeight : 4.75
                        )}
                        pl={1}
                    >
                        <Box
                            position="absolute"
                            ref={(element) => getRef(element, countKey)}
                        >
                            <Stack>
                                <Stack
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    right={0}
                                    bottom={0}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <BurstWrapper
                                        {...(showBurst && {
                                            show: true,
                                            alwaysVisible: true,
                                            skipAnimation: false,
                                            size: 50,
                                            duration: 50,
                                            onCompleteAction: () => {
                                                setShowBurst(false)
                                            }
                                        })}
                                    />
                                </Stack>
                                <CoreTypography
                                    customVariant={isLarge ? 'stat2' : 'stat3'}
                                >
                                    <Stack ref={countUpRef} />
                                </CoreTypography>
                            </Stack>
                        </Box>
                    </Box>
                    {!hideShopLink && (
                        <Button
                            variant="text"
                            onClick={() => handleRouteToShop()}
                            endIcon={
                                <LeafIcon
                                    icon={'arrow-right'}
                                    fontSize={'small'}
                                />
                            }
                            sx={{ width: 'fit-content' }}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    defaultMessage="Shop"
                                    description="button text that will open the plant shop popup"
                                />
                            </CoreTypography>
                        </Button>
                    )}
                </Stack>
            </Card>
        </Box>
    )
}

export default memo(TokensCard)
