import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { useEffect, useState } from 'react'
import { PreviousAchievementsProgress } from 'src/hooks/usePreviousAchievementsV3Progress/usePreviousAchievementsV3Progress'
import {
    AchievementFrequency,
    AchievementV3Progress
} from '../../../../graphql/generated/autogenerated'

const filterAchievement =
    (lastViewedAt: Date) => (achievement: AchievementV3Progress) => {
        const recentlyCompleted =
            new Date(achievement.lastCompletedAt).getTime() >
            lastViewedAt.getTime()
        const progressRemaining =
            achievement.achievementInfo.actionNumber -
            achievement.currentProgress
        const isOneTimeAchievement =
            achievement.achievementInfo.frequency ===
            AchievementFrequency.OneTime
        const isAchievementCompleted = achievement.total >= 1

        const isRecentlyCompletedOrInProgress =
            recentlyCompleted || progressRemaining > 0

        const isNotCompletedOneTimeAchievement = !(
            isOneTimeAchievement && isAchievementCompleted
        )

        return (
            isRecentlyCompletedOrInProgress && isNotCompletedOneTimeAchievement
        )
    }

const compareAchievements = (
    a: AchievementV3Progress,
    b: AchievementV3Progress
) => {
    const aPercentage = a.currentProgress / a.achievementInfo.actionNumber
    const bPercentage = b.currentProgress / b.achievementInfo.actionNumber
    if (aPercentage === bPercentage) {
        return a.achievementInfo.actionNumber - b.achievementInfo.actionNumber
    }
    return bPercentage - aPercentage
}

const prepareAchievements = (
    progress: AchievementV3Progress[],
    lastViewedAt: Date,
    withTransition: boolean
) =>
    progress
        .filter(filterAchievement(lastViewedAt))
        .sort(compareAchievements)
        .filter(
            (a, idx) =>
                (withTransition && idx < 3) ||
                a.achievementInfo.actionNumber - a.currentProgress > 0
        )

export const useAchievementsV3Progress = (
    progress: AchievementV3Progress[],
    previousProgress: PreviousAchievementsProgress,
    withTransition = true
) => {
    const hasAchievementsV3 = useIsFeatureEnabled(
        'achievements_v3',
        false,
        true
    )

    const [achievements, setAchievements] = useState(() =>
        prepareAchievements(
            progress,
            new Date(previousProgress?.updatedAt || 0),
            withTransition
        )
    )

    /**
     * Handles the transition of achievements
     * Remove the first achievement after delay if it's completed
     */
    useEffect(() => {
        if (!withTransition || !hasAchievementsV3) return

        const firstAchievement = achievements[0]
        if (!firstAchievement) return

        const { achievementInfo, currentProgress } = firstAchievement
        if (currentProgress - achievementInfo.actionNumber !== 0) return

        const timeout = setTimeout(() => {
            setAchievements((prev) => prev.slice(1))
        }, 2000)

        return () => clearTimeout(timeout)
    }, [achievements, withTransition, hasAchievementsV3])

    return achievements
}
