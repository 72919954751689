import { ClassNames, keyframes } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react'

export interface EmptyAnimationProps {
    full?: boolean
    empty?: boolean
    refill?: boolean
    iterationCount?: string
    duration?: number
    onAnimationComplete?: () => void
    children: ReactElement<any, any>
}

const EmptyAnimation: React.FC<EmptyAnimationProps> = ({
    full,
    empty,
    refill,
    iterationCount = '1',
    duration = 1,
    onAnimationComplete,
    children
}) => {
    const prefersReducedMotion = useMediaQuery(
        '(prefers-reduced-motion: reduce)'
    )

    const allowedIterationCount = useMemo(
        () => (!refill ? iterationCount : 'infinite'),
        [refill, iterationCount]
    )

    const emptyAnimation = useMemo(
        () =>
            keyframes({
                '0%': { opacity: 1 },
                '40%': { opacity: 1 },
                '80%': { opacity: 0 },
                ...(!refill && { '100%': { opacity: 0 } }),
                ...(refill && { '90%': { opacity: 0 }, '100%': { opacity: 0 } })
            }),
        [refill]
    )

    const emptyAnimationStyles = useMemo(() => {
        return {
            animation: emptyAnimation,
            animationName: emptyAnimation.name,
            animationDirection: refill ? 'reverse' : 'normal',
            animationDuration: `${duration}s`,
            animationTimingFunction: 'ease-in',
            animationIterationCount: allowedIterationCount,
            animationPlayState:
                (empty || refill) && !prefersReducedMotion
                    ? 'running'
                    : 'paused',
            animationFillMode: 'both'
        }
    }, [
        emptyAnimation,
        refill,
        duration,
        allowedIterationCount,
        empty,
        prefersReducedMotion
    ])

    useEffect(() => {
        if (empty === true || refill === true) {
            setTimeout(() => onAnimationComplete?.(), duration * 1000)
        }
    }, [empty, refill, onAnimationComplete, duration])

    const renderChildElement = useCallback(
        (css, cx) => {
            return React.cloneElement(children, {
                className: cx(css(emptyAnimationStyles))
            })
        },
        [children, emptyAnimationStyles]
    )

    return (
        <ClassNames>{({ css, cx }) => renderChildElement(css, cx)}</ClassNames>
    )
}

export default EmptyAnimation
