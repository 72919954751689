import {
    Button,
    Card,
    DialogActions,
    DialogContent,
    Icon,
    Stack,
    useMediaQuery
} from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { memo, useMemo, useState } from 'react'
import {
    FormattedMessage,
    MessageDescriptor,
    defineMessages,
    useIntl
} from 'react-intl'
import { useAchievementIcons } from '../../../hooks'

const messages = defineMessages({
    levelThree: {
        defaultMessage: 'Virtual Bench, Thrive water bottle + stickers',
        description: 'description of the level 3 achievement'
    },
    levelFive: {
        defaultMessage: 'Thrive hat',
        description: 'description of the level 5 achievement'
    },
    levelTen: {
        defaultMessage: 'Thrive t-shirt',
        description: 'description of the level 10 achievement'
    }
})

interface LevelDescriptionProps {
    level: number
    description: MessageDescriptor
}
const LevelDescription: React.FC<LevelDescriptionProps> = ({
    level,
    description
}) => {
    const { formatNumber, formatMessage } = useIntl()
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    return (
        <Stack alignItems="flex-start" direction="row" gap={1}>
            <CoreTypography
                variant="overline"
                color="text.primary"
                whiteSpace="nowrap"
            >
                <FormattedMessage
                    defaultMessage="Level {level}"
                    description="title of the level achievement"
                    values={{ level: formatNumber(level) }}
                />
            </CoreTypography>
            <CoreTypography
                variant="caption"
                lineHeight={isMobile ? 1.3 : 1.45}
            >
                {formatMessage(description)}
            </CoreTypography>
        </Stack>
    )
}

export interface AchievementReferralDialogProps {
    open: boolean
    onClose: () => void
}

const AchievementReferralDialog: React.FC<AchievementReferralDialogProps> = ({
    open,
    onClose
}) => {
    const { gradient, breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const { formatNumber } = useIntl()
    const { achievements } = useAppSelector((state) => state.achievement)
    const getAchievementIcon = useAchievementIcons()

    const communityBuilderAchievement = useMemo(() => {
        return achievements.find(
            (a) => a.upc === 'achievement.communitybuilder'
        )
    }, [achievements])

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            dialogTitle={
                <CoreTypography variant="h4">
                    <FormattedMessage
                        defaultMessage="Referral achievements"
                        description="title of the referral achievements dialog"
                    />
                </CoreTypography>
            }
            PaperProps={{ sx: { width: 672 } }}
        >
            <DialogContent>
                <Stack direction={isMobile ? 'column' : 'row'} gap={2.625}>
                    <Stack>
                        <Card elevation={0} sx={{ background: gradient.main }}>
                            <Stack
                                px={3}
                                py={2}
                                gap={2}
                                alignItems="center"
                                justifyContent="center"
                                minWidth={226}
                            >
                                <Icon
                                    titleAccess={
                                        communityBuilderAchievement.title
                                    }
                                    component={getAchievementIcon(
                                        communityBuilderAchievement
                                    )}
                                    sx={{ width: 56.8, height: 64 }}
                                />
                                <CoreTypography
                                    variant="overline"
                                    color="accent.dark"
                                    textAlign="center"
                                >
                                    <FormattedMessage
                                        defaultMessage="Community Builder Achievement"
                                        description="title of the community builder achievement"
                                    />
                                </CoreTypography>
                            </Stack>
                        </Card>
                    </Stack>
                    <Stack gap={1.5}>
                        <Stack gap={1}>
                            <CoreTypography variant="h3">
                                <FormattedMessage
                                    defaultMessage="Rewards for referrals"
                                    description="title of the rewards for referrals section"
                                />
                            </CoreTypography>
                            <CoreTypography variant="body1">
                                <FormattedMessage
                                    defaultMessage="Get rewarded for bringing more of your friends into Thrive - share the love and grow your community! Earn prizes when you reach level {three}, {five}, {ten}!"
                                    description="description of the rewards for referrals section"
                                    values={{
                                        three: formatNumber(3),
                                        five: formatNumber(5),
                                        ten: formatNumber(10)
                                    }}
                                />
                            </CoreTypography>
                        </Stack>
                        <Stack gap={1.25}>
                            <LevelDescription
                                level={3}
                                description={messages.levelThree}
                            />
                            <LevelDescription
                                level={5}
                                description={messages.levelFive}
                            />
                            <LevelDescription
                                level={10}
                                description={messages.levelTen}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Close"
                            description="close button of the referral achievements dialog"
                        />
                    </CoreTypography>
                </Button>
            </DialogActions>
        </LeafDialog>
    )
}

export default memo(AchievementReferralDialog)
