import { Box, LinearProgress } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { UserProgressDetail } from '../../../hooks'

export type AchievementProgressBarProps = {
    userProgressDetail: UserProgressDetail
}

const messages = defineMessages({
    AchievementProgressBar: {
        defaultMessage:
            'current achievement progress {percentageDone} percent out of 100 percent',
        description:
            'aria description of the current progress in percentage of the achievement'
    }
})

const AchievementProgressBar: React.FC<AchievementProgressBarProps> = ({
    userProgressDetail
}) => {
    const intl = useIntl()

    const { percentageDone } = useMemo(() => {
        return {
            percentageDone:
                (100 / userProgressDetail.total) *
                userProgressDetail.currentProgress
        }
    }, [userProgressDetail])

    return (
        <Box display="flex">
            <Box display="flex" flexGrow={1} mr={2} justifyContent="center">
                <LinearProgress
                    variant="determinate"
                    value={percentageDone}
                    color={'success'}
                    sx={{
                        width: '100%',
                        my: 'auto'
                    }}
                    aria-label={intl.formatMessage(
                        messages.AchievementProgressBar,
                        {
                            percentageDone: Math.round(percentageDone)
                        }
                    )}
                />
            </Box>
            <CoreTypography variant="body2">
                {`${userProgressDetail.currentProgress}/${userProgressDetail.total}`}
            </CoreTypography>
        </Box>
    )
}

export default React.memo(AchievementProgressBar)
