import { Box, Button, Divider, List, Stack } from '@mui/material'
import {
    useAppSelector,
    useIsFeatureEnabled
} from '@thriveglobal/thrive-web-core'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { memo, useCallback, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { usePreviousAchievementsV3Progress } from '../../../../hooks'
import { useAchievementProgressions } from '../../../../hooks/useAchievementProgressions/useAchievementProgressions'
import { ROUTES } from '../../../../routes'
import { GQLNullValue } from '../../../../utils/Nulls'
import AchievementProgress from '../AchievementProgress/AchievementProgress'
import AchievementsEarnedWidgetBanner from '../AchievementsEarnedWidgetBanner/AchievementsEarnedWidgetBanner'
import AchievementsV3ProgressList from '../AchievementsV3ProgressList/AchievementsV3ProgressList'
import Tokens from '../Tokens/Tokens'

const messages = defineMessages({
    earnFirstAchievement: {
        defaultMessage: 'Earn your first Achievement!',
        description: 'first time achievement subtitle'
    },
    keepMakingProgress: {
        defaultMessage: 'Keep making progress!',
        description: 'second time achievement subtitle'
    }
})

const messagesV3 = defineMessages({
    earnFirstAchievement: {
        defaultMessage: 'Earn your first Badge',
        description: 'first time badge subtitle'
    },
    keepMakingProgress: {
        defaultMessage: 'Keep making progress towards your next badge!',
        description: 'second time badge subtitle'
    }
})

type AchievementWidgetProps = Omit<
    React.ComponentProps<typeof Stack>,
    'children'
>

const AchievementWidget: React.FC<AchievementWidgetProps> = ({
    ...stackProps
}) => {
    const history = useHistory()
    const { formatMessage } = useIntl()
    const { achievements, progress, progressV3 } = useAppSelector(
        (state) => state.achievement
    )
    const achievementProgressions = useAchievementProgressions(
        achievements,
        progress
    )
    const { newAchievementsCount, previousProgress } =
        usePreviousAchievementsV3Progress()

    const hasAchievementsV3 = useIsFeatureEnabled(
        'achievements_v3',
        false,
        true
    )

    const message = useMemo(() => {
        if (hasAchievementsV3) {
            const anyEarned = progressV3.some((a) => a.total > 0)
            if (anyEarned) {
                return formatMessage(messagesV3.keepMakingProgress)
            } else {
                return formatMessage(messagesV3.earnFirstAchievement)
            }
        } else {
            const isAnyLevelCompleted = progress.some((progress) =>
                progress?.levels?.some(
                    (level) => level.completedAt !== GQLNullValue
                )
            )
            if (isAnyLevelCompleted) {
                return formatMessage(messages.keepMakingProgress)
            } else {
                return formatMessage(messages.earnFirstAchievement)
            }
        }
    }, [formatMessage, hasAchievementsV3, progress, progressV3])

    const handleViewShop = useCallback(() => {
        history.push(ROUTES.SHOP)
    }, [history])

    return (
        <Stack p={2} gap={2} {...stackProps}>
            <Stack gap={1}>
                <CoreTypography variant="overline" color="text.secondary">
                    <FormattedMessage
                        defaultMessage="Tokens"
                        description="tokens title"
                    />
                </CoreTypography>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Tokens />
                    <Button
                        onClick={handleViewShop}
                        variant="text"
                        endIcon={
                            <LeafIcon icon="arrow-right" fontSize="small" />
                        }
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="View shop"
                                description="view shop button"
                            />
                        </CoreTypography>
                    </Button>
                </Stack>
                <Divider />
            </Stack>
            <Stack gap={1}>
                <Stack gap={1}>
                    <CoreTypography variant="overline" color="text.secondary">
                        {hasAchievementsV3 ? (
                            <FormattedMessage
                                defaultMessage="Badges"
                                description="badges title"
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Achievements"
                                description="achievements title"
                            />
                        )}
                    </CoreTypography>
                    {newAchievementsCount > 0 ? (
                        <AchievementsEarnedWidgetBanner
                            count={newAchievementsCount}
                        />
                    ) : (
                        <CoreTypography variant="body1" color="text.secondary">
                            {message}
                        </CoreTypography>
                    )}
                </Stack>
                {hasAchievementsV3 ? (
                    <AchievementsV3ProgressList
                        progress={progressV3}
                        previousProgress={previousProgress}
                    />
                ) : (
                    <List sx={{ padding: 0 }}>
                        {achievementProgressions
                            .slice(0, 3)
                            .map((currentAchievementProgress) => (
                                <li key={currentAchievementProgress.id}>
                                    <Box marginBottom={1}>
                                        <AchievementProgress
                                            currentAchievementProgress={
                                                currentAchievementProgress
                                            }
                                        />
                                    </Box>
                                </li>
                            ))}
                    </List>
                )}
            </Stack>
        </Stack>
    )
}

export default memo(AchievementWidget)
