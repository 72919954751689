import { Box, Grow, Stack, Zoom, useMediaQuery } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import {
    ProductInfo,
    UserPlantInfo
} from '../../../graphql/generated/autogenerated'
import { FlagKey, useFlagStore } from '../../../shared'
import Plant from '../../../shared/components/features/Garden/Plant/Plant'
import { PlantType, isTrophy } from '../../../shared/enums/plantTypes'

const BG_URL =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/24d4dfbb-9e27-4e37-b608-a3ec18b0e200/cms'

const DIALOG_TITLE = defineMessage({
    defaultMessage: ' Way to go, you just grew your first plant. Keep it up!',
    description: 'title for plant growth celebration dialog'
})

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return (
        <Zoom
            ref={ref}
            {...props}
            timeout={{
                enter: 800,
                exit: 0
            }}
            easing={{
                enter: 'ease-in-out',
                exit: 'ease-out'
            }}
        />
    )
})

type PlantGrowthCelebrationProps = {
    onOpen?: () => void
}

const PlantGrowthCelebration: React.FC<PlantGrowthCelebrationProps> = ({
    onOpen
}) => {
    const { formatMessage } = useIntl()
    const { breakpoints } = useTheme()
    const isSmallScreen = useMediaQuery(breakpoints.down('sm'))
    const { products = [], plantedPlants = [] } = useAppSelector(
        (state) => state.garden
    )
    const { getFlag, setFlag, loading: loadingFlags } = useFlagStore()
    const [open, setOpen] = React.useState(false)
    const [showPlant, setShowPlant] = useState(false)

    const handleCloseDialog = useCallback(() => {
        setOpen(false)
        setFlag(FlagKey.VIEWED_FIRST_PLANT_CELEBRATION, String(true))
    }, [setFlag])

    const handleOpenDialog = useCallback(() => {
        setOpen(true)
        onOpen?.()
    }, [onOpen])

    const { plant, product } = useMemo(() => {
        let plant: UserPlantInfo | undefined
        let product: ProductInfo | undefined
        const plantedExcludingOnboarding = plantedPlants.filter(
            (p) => p.productUpc !== PlantType.novafutura
        )
        if (plantedExcludingOnboarding.length === 1) {
            plant = plantedExcludingOnboarding[0] as UserPlantInfo
            product = products.find((p) => p.upc === plant.productUpc)
        }
        return {
            plant,
            product
        }
    }, [plantedPlants, products])

    useEffect(() => {
        if (loadingFlags || !plant || !product) {
            return
        }

        const viewedCelebration = Boolean(
            getFlag(FlagKey.VIEWED_FIRST_PLANT_CELEBRATION)
        )

        if (!viewedCelebration && !isTrophy(plant.productUpc as PlantType)) {
            handleOpenDialog()
        }
    }, [getFlag, handleOpenDialog, loadingFlags, plant, product])

    if (!plant || !product) {
        return null
    }

    return (
        <LeafDialog
            open={open}
            onClose={handleCloseDialog}
            TransitionComponent={Transition}
            TransitionProps={{
                onEntering: () => setShowPlant(true)
            }}
            fullWidth
            maxWidth="sm"
            dialogTitle={
                <CoreTypography fontSize={!isSmallScreen ? 36 : 24}>
                    {formatMessage(DIALOG_TITLE)}
                </CoreTypography>
            }
            PaperProps={{
                sx: {
                    backgroundImage: `url(${BG_URL})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }
            }}
        >
            <Stack
                marginTop={4}
                alignItems="center"
                justifyContent="flex-end"
                width="100%"
                height="100%"
                maxWidth={!isSmallScreen ? 900 : 630}
            >
                <Grow
                    in={showPlant}
                    timeout={{
                        enter: 500,
                        exit: 0
                    }}
                >
                    <Box
                        width="100%"
                        height="100%"
                        maxWidth={isSmallScreen ? 191 : 273}
                        maxHeight={isSmallScreen ? 191 : 273}
                    >
                        <Plant plant={plant} product={product} />
                    </Box>
                </Grow>
            </Stack>
        </LeafDialog>
    )
}

export default PlantGrowthCelebration
