import { AchievementUpcs } from '../shared/enums'

export const isJourneyRelatedAchievement = (upc: AchievementUpcs | string) => {
    const journeyAchievements: (AchievementUpcs | string)[] = [
        AchievementUpcs.recharge,
        AchievementUpcs.focus,
        AchievementUpcs.connect,
        AchievementUpcs.money,
        AchievementUpcs.move,
        AchievementUpcs.food,
        AchievementUpcs.stress
    ]

    return journeyAchievements.includes(upc)
}
