import { IconButton, LinearProgress, Stack } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { useCallback, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { CurrentAchievementProgress } from '../../../../hooks/useAchievementProgressions/useAchievementProgressions'
import { useGetAchievementDialogInfo } from '../../../../hooks/useGetAchievementDialogInfo/useGetAchievementDialogInfo'
import AchievementIcon from '../../../../shared/components/icons/achievementsV2/AchievementIcon'
import { AchievementIcons, AchievementUpcs } from '../../../../shared/enums'
import AchievementInfo from '../AchievementInfo/AchievementInfo'
import Claim from '../Claim/Claim'

const journeysGeneralImageUrl =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/9e5ec3d8-4546-458e-8816-a07961487400/cms'

export type AchievementProgressProps = {
    currentAchievementProgress: CurrentAchievementProgress
}

const ariaLabels = defineMessages({
    viewDetails: {
        defaultMessage: 'View details',
        description: 'label for view details button'
    },
    progress: {
        defaultMessage: 'Progress to next level.',
        description: 'label for progress bar'
    }
})

const AchievementProgress: React.FC<AchievementProgressProps> = ({
    currentAchievementProgress
}) => {
    const { formatMessage } = useIntl()
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    const {
        isJourneyAchievement,
        description,
        buttonText,
        buttonRoute,
        title
    } = useGetAchievementDialogInfo(currentAchievementProgress)

    const handleTrackClick = useCallback(
        (upc: AchievementUpcs) => {
            if (isJourneyAchievement) return
            const activityTypeMap = {
                [AchievementUpcs.microStepper]: 'microstep_viewed',
                [AchievementUpcs.dailyReflection]: 'dci_viewed',
                [AchievementUpcs.mindfulMoments]: 'reset_viewed'
            } as const

            // should be there but just in case something fascinating happens
            if (!activityTypeMap[upc]) return
        },
        [
            currentAchievementProgress.currentLevel,
            currentAchievementProgress.id,
            isJourneyAchievement
        ]
    )

    const handleViewDetails = useCallback(() => {
        handleTrackClick(currentAchievementProgress.upc)
        setDialogOpen(true)
    }, [currentAchievementProgress.upc, handleTrackClick])

    return (
        <Stack direction="row" alignItems="center">
            <Stack direction="row" alignItems="center" gap={1.5} flex={1}>
                <AchievementIcon
                    achievementIcon={
                        currentAchievementProgress.upc as AchievementIcons
                    }
                />
                <Stack gap={1} flex={1} maxWidth="75%">
                    <Stack
                        direction="row"
                        alignItems="baseline"
                        gap={0.5}
                        flexWrap="wrap"
                    >
                        <CoreTypography variant="body1" lineHeight={1}>
                            {currentAchievementProgress.title}
                        </CoreTypography>
                        <CoreTypography
                            variant="body2"
                            color="text.secondary"
                            lineHeight={1}
                        >
                            <FormattedMessage
                                defaultMessage="Level {level}"
                                description="achievement level"
                                values={{
                                    level: currentAchievementProgress.currentLevel
                                }}
                            />
                        </CoreTypography>
                    </Stack>
                    <LinearProgress
                        aria-label={formatMessage(ariaLabels.progress)}
                        variant="determinate"
                        color="accent"
                        value={currentAchievementProgress.progressPercentage}
                    />
                </Stack>
            </Stack>
            <Stack width={65} alignItems="center" justifyContent="center">
                {currentAchievementProgress?.canClaim ? (
                    <Claim
                        currentAchievementProgress={currentAchievementProgress}
                    />
                ) : (
                    <IconButton
                        aria-label={formatMessage(ariaLabels.viewDetails)}
                        color="primary"
                        onClick={handleViewDetails}
                    >
                        <LeafIcon
                            icon="arrow-right"
                            color="primary"
                            fontSizeAdjust={14}
                        />
                    </IconButton>
                )}
            </Stack>
            <AchievementInfo
                open={dialogOpen}
                title={title}
                description={description}
                buttonText={buttonText}
                buttonRoute={buttonRoute}
                upc={currentAchievementProgress.upc}
                imageUrl={
                    isJourneyAchievement ? journeysGeneralImageUrl : undefined
                }
                onClose={() => setDialogOpen(false)}
            />
        </Stack>
    )
}

export default AchievementProgress
