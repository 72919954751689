import { Box, Fade } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    memo
} from 'react'
import { useActiveRefDimensions } from '../../../../hooks'

export type OverlappingComponentsListProps = {
    components: { [key: string]: React.ReactNode }
    activeKey: string
    onSettledWidth?: (width: string) => void
}

const OverlappingComponentsList: React.FC<OverlappingComponentsListProps> = ({
    components,
    activeKey,
    onSettledWidth
}) => {
    const { spacing } = useTheme()
    const { activeComponentWidth, activeComponentHeight, getRef } =
        useActiveRefDimensions(activeKey)

    useEffect(() => {
        if (activeComponentWidth > 0) {
            onSettledWidth?.(spacing(activeComponentWidth))
        }
    }, [activeComponentWidth, spacing, onSettledWidth])

    const componentListByKey = useMemo(
        () => (
            <Box height="100%">
                {Object.keys(components).map((key) => (
                    <Box
                        key={key}
                        sx={{ position: 'absolute', right: 0, bottom: 0 }}
                        ref={(element) => getRef(element, key)}
                        display="flex"
                        flexGrow={1}
                        width="100%"
                        {...(key === activeKey && {
                            minHeight: '100%'
                        })}
                    >
                        <Fade
                            in={key === activeKey}
                            mountOnEnter
                            unmountOnExit
                            timeout={1000}
                        >
                            <Box
                                data-testid={`overlapping-${key}`}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                flexGrow={1}
                                width="100%"
                            >
                                {components[key]}
                            </Box>
                        </Fade>
                    </Box>
                ))}
            </Box>
        ),
        [components, activeKey, getRef]
    )

    return (
        <Box
            data-testid="overlapping-components"
            position="relative"
            width="100%"
            height="100%"
            minHeight={spacing(activeComponentHeight)}
        >
            {componentListByKey}
        </Box>
    )
}

export default memo(OverlappingComponentsList)
