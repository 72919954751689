import { Box, Stack } from '@mui/material'
import { claimAchievement } from '@thriveglobal/thrive-web-core'
import {
    BurstNotification,
    CoreTypography,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { useLocation } from 'react-router-dom'
import {
    getJourneySourceFromCurrentLocation,
    LocationState
} from '../../../../utils/tracking'
import { useActiveRefDimensions, UserProgressDetail } from '../../../hooks'
import { HiddenDescription } from '../../elements'
import { defineMessages, useIntl } from 'react-intl'
import { Avo } from '@thriveglobal/thrive-web-tracking'

const messages = defineMessages({
    claimedLevels: {
        defaultMessage: 'Claimed levels: {firstLevel} to {lastLevel}',
        description:
            'descriptions of the claimed levels E.G claimed levels: 4 to 9'
    },
    claimedLevel: {
        defaultMessage: 'Claimed level {level}',
        description: 'descriptions of the claimed levels E.G claimed level 6'
    }
})

export type ClaimCurrencyButtonProps = {
    userProgressDetail: UserProgressDetail
    levels: number[]
    text: string
    ariaLabel: string
    allowFocus?: boolean
    onClaimStart?: () => void
    onClaimComplete?: () => void
}

const ClaimCurrencyButton: React.FC<ClaimCurrencyButtonProps> = ({
    userProgressDetail,
    levels,
    text,
    ariaLabel,
    allowFocus,
    onClaimStart,
    onClaimComplete
}) => {
    const { spacing } = useTheme()
    const { formatMessage } = useIntl()
    const ref = useRef(null)
    const location = useLocation()
    const [showBurst, setShowBurst] = useState(false)
    const [loading, setLoading] = useState(false)
    const [claimedAriaLabel, setClaimedAriaLabel] = useState('')
    const { activeComponentWidth, activeComponentHeight, getRef } =
        useActiveRefDimensions()

    const createAriaLabel = useCallback(
        (levels) => {
            if (levels?.length > 1) {
                return formatMessage(messages.claimedLevels, {
                    firstLevel: levels[0],
                    lastLevel: levels[levels.length - 1]
                })
            } else {
                return formatMessage(messages.claimedLevel, {
                    level: levels[0]
                })
            }
        },
        [formatMessage]
    )

    const handleOnClaim = useCallback(() => {
        if (!loading) {
            setLoading(true)
            setClaimedAriaLabel(createAriaLabel(levels))
            setShowBurst(true)
            onClaimStart?.()
            claimAchievement(
                userProgressDetail.achievementId as any,
                levels
            ).then(() => {
                onClaimComplete?.()
                const achievementCenterSource = 'achievement-center'
                const journeySource =
                    getJourneySourceFromCurrentLocation(location)
                const source = journeySource || achievementCenterSource

                Avo.achievementCompleted({
                    achievementId: userProgressDetail.achievementId,
                    activityType: 'achievement_claimed',
                    featureType: 'achievement',
                    level: userProgressDetail.level,
                    source: 'referral'
                })
                setLoading(false)
            })
        }
    }, [
        loading,
        location,
        userProgressDetail,
        levels,
        createAriaLabel,
        onClaimStart,
        onClaimComplete
    ])

    const BurstWrapper = useMemo(
        () => (showBurst ? BurstNotification : Box),
        [showBurst]
    )

    useEffect(() => {
        if (ref?.current && allowFocus && !showBurst) {
            ref.current.focus()
        }
    }, [ref, showBurst, allowFocus])

    return (
        <Box
            minWidth={spacing(activeComponentWidth)}
            minHeight={spacing(activeComponentHeight)}
        >
            <Stack
                direction="row"
                data-testid="reaction-button"
                position="absolute"
                sx={{ top: '25%', transform: 'translateY(-25%)' }}
            >
                <BurstWrapper
                    {...(showBurst && {
                        show: true,
                        alwaysVisible: true,
                        skipAnimation: false,
                        size: 50
                    })}
                >
                    {showBurst && (
                        <HiddenDescription
                            component="output"
                            aria-live="polite"
                            aria-atomic="true"
                        >
                            {claimedAriaLabel}
                        </HiddenDescription>
                    )}
                    <Box ref={(element) => getRef(element)}>
                        <LoadingButton
                            loading={loading}
                            fixWidth={true}
                            ref={ref}
                            variant="text"
                            aria-label={ariaLabel}
                            onClick={(ev) => {
                                ev.stopPropagation()
                                ev.preventDefault()
                                handleOnClaim()
                            }}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                {text}
                            </CoreTypography>
                        </LoadingButton>
                    </Box>
                </BurstWrapper>
            </Stack>
        </Box>
    )
}

export default memo(ClaimCurrencyButton)
