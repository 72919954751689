import { Box, Card, Icon, IconButton, Link, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    ProductInfo,
    ProductType
} from '../../../graphql/generated/autogenerated'
import GardenObject from '../../../shared/components/features/Garden/GardenObject/GardenObject'
import Plant from '../../../shared/components/features/Garden/Plant/Plant'
import { ProductTypes } from '../../../shared/enums'
import { getLevelForActions, getLevelForCost } from '../../../utils'
import PlantActionsExplainerDialog from '../PlantActionsExplainerDialog/PlantActionsExplainerDialog'

const messages = defineMessages({
    productName: {
        defaultMessage: 'Name',
        description: 'product name'
    },
    productLevel: {
        defaultMessage: 'Level',
        description: 'product level'
    },
    productGrowth: {
        defaultMessage: 'Growth',
        description: 'product growth'
    },
    productCost: {
        defaultMessage: 'Cost',
        description: 'product cost'
    },
    productGrowthAmount: {
        defaultMessage: '{amount} Actions',
        description: 'product actions needed'
    },
    productCostAmount: {
        defaultMessage: '{amount} Tokens',
        description: 'product cost amount'
    }
})

type ProductInfoCardProps = {
    productInfo: ProductInfo
    action?: {
        text: string
        onClick: () => void
    }
    fullWidth?: boolean
}

const ProductInfoCard: React.FC<ProductInfoCardProps> = ({
    productInfo,
    action,
    fullWidth
}) => {
    const { palette } = useTheme()
    const { formatMessage } = useIntl()
    const [openActionsExplainer, setOpenActionsExplainer] =
        useState<boolean>(false)

    return (
        <React.Fragment>
            <Card
                elevation={0}
                sx={{
                    display: 'flex',
                    direction: 'row',
                    alignItems: 'center',
                    p: 2,
                    width: fullWidth ? '100%' : 320,
                    minHeight: 168,
                    bgcolor: palette.background.default,
                    overflow: 'unset'
                }}
            >
                <Stack minWidth={140} height={136} justifyContent="flex-end">
                    <Box position="relative">
                        <Box
                            width={134}
                            height={134}
                            position="absolute"
                            bottom={action ? 12 : 4}
                            right={12}
                        >
                            {productInfo?.productType === ProductTypes.Plant ? (
                                <Plant
                                    isPreview
                                    product={productInfo}
                                    showSway={false}
                                />
                            ) : (
                                <GardenObject productInfo={productInfo} />
                            )}
                        </Box>
                    </Box>
                    {!!action && (
                        <Box>
                            <Link
                                href="javascript:void(0)"
                                onClick={() => action?.onClick?.()}
                            >
                                {action.text}
                            </Link>
                        </Box>
                    )}
                </Stack>
                <Stack gap={1}>
                    <Box>
                        <CoreTypography
                            variant="overline"
                            color={palette.text.primary}
                        >
                            {formatMessage(messages.productName)}
                        </CoreTypography>
                        <CoreTypography>{productInfo?.title}</CoreTypography>
                    </Box>
                    <Box>
                        <CoreTypography
                            variant="overline"
                            color={palette.text.primary}
                        >
                            {formatMessage(messages.productLevel)}
                        </CoreTypography>
                        <CoreTypography>
                            {productInfo.productType === ProductTypes.Plant
                                ? formatMessage(
                                      getLevelForActions(
                                          productInfo?.plantWateringSteps
                                      ).text
                                  )
                                : formatMessage(
                                      getLevelForCost(productInfo?.cost).text
                                  )}
                        </CoreTypography>
                    </Box>
                    <Box>
                        <CoreTypography
                            variant="overline"
                            color={palette.text.primary}
                        >
                            {productInfo?.productType === ProductTypes.Plant
                                ? formatMessage(messages.productGrowth)
                                : formatMessage(messages.productCost)}
                        </CoreTypography>
                        <Stack direction="row" gap={0.5} alignItems="center">
                            <CoreTypography>
                                {productInfo?.productType === ProductTypes.Plant
                                    ? formatMessage(
                                          messages.productGrowthAmount,
                                          {
                                              amount: productInfo?.plantWateringSteps
                                          }
                                      )
                                    : formatMessage(
                                          messages.productCostAmount,
                                          {
                                              amount: productInfo?.cost
                                          }
                                      )}
                            </CoreTypography>
                            {productInfo?.productType === ProductType.Plant && (
                                <Link
                                    href="javascript:void(0)"
                                    onClick={() =>
                                        setOpenActionsExplainer(true)
                                    }
                                    sx={{ height: 16 }}
                                >
                                    <LeafIcon
                                        color="primary"
                                        fontSize="small"
                                        icon="circle-info"
                                        iconStyle="regular"
                                    />
                                </Link>
                            )}
                        </Stack>
                    </Box>
                </Stack>
            </Card>
            <PlantActionsExplainerDialog
                open={openActionsExplainer}
                onClose={() => setOpenActionsExplainer(false)}
            />
        </React.Fragment>
    )
}

export default ProductInfoCard
