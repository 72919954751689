import { SvgIcon, SvgIconProps } from '@mui/material'
import { ReactElement } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { SwayAnimation } from '../../elements'

export interface PlantIconProps extends SvgIconProps {
    showsway?: string
    showDirt?: boolean
    showsparkle?: string
}

export interface BasePlantProps extends PlantIconProps {
    showsway?: string
    showDirt?: boolean
    children: ReactElement<any, any>
}

const BasePlant: React.FC<BasePlantProps> = ({
    showsway,
    showDirt = true,
    children,
    ...props
}) => {
    const prefix = 'basePlant'
    const uuid_1 = `${prefix}-${uuidv4()}`
    return (
        <SvgIcon
            height="100%"
            width="100%"
            viewBox="0 0 208 208"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath={`url(#${uuid_1})`}>
                {showDirt && (
                    <>
                        {' '}
                        <path
                            d="M152.815 178.169L155.131 178.785L157.235 177.126L158.141 178.359L158.787 176.036L159.222 172.612L155.037 170.776L152.109 169.674L151.439 167.068L151.098 164.035L147.783 163.49L144.762 163.833L141.658 166.321L138.836 163.585L135.627 165.705L133.593 162.814L130.125 164.035L127.82 162.115L127.738 158.04L127.174 156.784L123.858 156.796L120.778 157.139L119.12 153.644L115.793 155.552L113.395 154.13L110.444 155.67L107.834 152.72L104.707 153.277L101.591 154.438L98.7816 150.611L95.6308 153.881L92.6329 152.85L89.6467 153.822L86.4959 151.725L83.5215 152.839L80.3472 151.891L77.4669 153.407L74.4337 153.727L72.1058 155.256L68.5083 154.071L68.0968 159.331L65.9807 161.286L61.713 162.4L65.6632 164.864L67.1093 165.776L66.4627 167.163L64.9813 166.073L59.9848 167.944L58.6681 164.556L59.2324 168.572L58.2918 170.883L55 173.086L55.9405 175.965L55.1528 179.354L59.4205 179.152L63.3237 181.593L63.2414 184.768L61.8659 187.327L63.888 189.164L65.5574 189.839L68.2144 191.806L70.6951 188.583L73.9516 191.379L76.5028 188.263L79.5595 191.166L82.3458 189.78L86.4136 192.896L82.1812 195.348L83.9565 197.741L87.507 196.912L89.0706 198.879L92.1626 198.381L95.2899 199.862L97.5941 202.125L100.663 202.836L103.719 201.995L106.329 205.893L109.351 205.111L112.29 205.087L115.006 202.303L117.827 204.838L120.614 203.689L123.235 204.269L125.645 205.798L128.126 207.492L131.065 207.35L133.875 204.376L136.908 205.608L138.942 202.741L142.575 204.092L144.186 201.011L147.583 201.071L150.146 199.637L152.215 197.576L153.567 194.649L157.482 194.72L157.247 190.953L156.013 188.133L157.235 185.088L156.671 184.922L156.401 184.247L153.849 184.354L154.202 181.403L150.652 180.242L152.815 178.169Z"
                            fill="#D7CCC8"
                        />
                        <path
                            d="M119.72 152.922C119.838 152.993 119.955 153.076 120.073 153.159C121.789 151.535 124.587 151.452 127.762 153.301C130.583 154.936 133.099 157.755 134.827 160.895C136.062 160.895 137.473 161.298 138.977 162.175C142.728 164.355 145.914 168.774 147.195 173.122L137.073 167.234L132.006 164.284L125.316 160.386L124.47 159.9L119.191 156.831L118.45 156.405L114.112 153.881C114.982 151.926 117.157 151.452 119.708 152.933L119.72 152.922Z"
                            fill="#A1887F"
                        />
                        <path
                            d="M136.285 195.715C134.863 192.528 132.429 189.555 129.654 187.932C126.28 185.965 123.4 186.605 122.259 189.176L135.944 197.137L137.061 197.789C136.861 197.09 136.603 196.403 136.297 195.715H136.285Z"
                            fill="#A1887F"
                        />
                        <path
                            d="M124.47 173.193C122.836 169.52 120.014 166.073 116.804 164.212C112.913 161.95 109.586 162.672 108.257 165.646L124.058 174.84L125.34 175.586C125.105 174.781 124.811 173.987 124.458 173.193H124.47Z"
                            fill="#A1887F"
                        />
                        <path
                            d="M76.3029 151.713C77.5962 148.799 80.8527 148.088 84.6619 150.303C87.8009 152.128 91.8687 160.137 91.7629 160.706L76.3029 151.713Z"
                            fill="#A1887F"
                        />
                        <path
                            d="M90.258 172.15C88.6591 168.549 85.8963 165.184 82.7573 163.359C78.9482 161.144 75.6916 161.855 74.3984 164.769L89.8583 173.762L91.1163 174.496C90.8811 173.714 90.599 172.932 90.258 172.15Z"
                            fill="#A1887F"
                        />
                        <path
                            d="M94.3258 192.623C94.1495 192.623 93.9966 192.517 93.9496 192.339C93.0914 189.401 90.9164 186.498 88.5298 185.112C88.4593 185.064 88.3887 185.041 88.3182 185.005L88.1889 184.946C88.1066 184.91 88.0478 184.839 88.0125 184.768C86.2725 181.095 83.451 177.861 80.4765 176.131C77.9136 174.638 75.4917 174.401 73.8223 175.491C73.6577 175.598 73.4343 175.563 73.305 175.42C72.0588 173.963 70.6598 172.766 69.2607 171.949C67.3092 170.812 65.4281 170.456 63.9703 170.93C62.9239 171.274 62.101 172.044 61.6072 173.146C61.5132 173.347 61.278 173.43 61.0899 173.347C60.8901 173.252 60.796 173.027 60.8901 172.826C61.4779 171.499 62.4654 170.587 63.7351 170.172C65.4163 169.627 67.5208 170.006 69.6722 171.262C71.0713 172.079 72.4585 173.24 73.7047 174.65C75.6093 173.596 78.2075 173.88 80.888 175.444C83.9565 177.233 86.8604 180.539 88.6826 184.294C88.7649 184.342 88.859 184.377 88.9413 184.425C91.5277 185.929 93.7968 188.95 94.7255 192.114C94.7843 192.327 94.6668 192.54 94.4551 192.611C94.4199 192.611 94.3846 192.623 94.3376 192.623H94.3258Z"
                            fill="#A1887F"
                        />
                        <path
                            d="M153.168 192.102C152.979 192.102 152.803 191.96 152.78 191.758C152.074 187.067 148.677 181.996 144.703 179.686C142.163 178.205 139.789 178.133 138.343 179.496C138.19 179.65 137.943 179.638 137.79 179.484C137.096 178.761 136.356 178.181 135.603 177.743C134.298 176.984 133.052 176.747 132.1 177.055C131.418 177.28 130.889 177.778 130.571 178.501C130.477 178.702 130.242 178.785 130.054 178.702C129.854 178.607 129.76 178.382 129.854 178.181C130.266 177.245 130.971 176.593 131.865 176.309C133.052 175.93 134.522 176.19 136.015 177.055C136.732 177.47 137.437 178.015 138.107 178.667C139.824 177.316 142.41 177.423 145.114 178.998C149.276 181.427 152.838 186.747 153.579 191.652C153.614 191.865 153.461 192.066 153.25 192.102C153.226 192.102 153.215 192.102 153.191 192.102H153.168Z"
                            fill="#A1887F"
                        />
                    </>
                )}

                <SwayAnimation show={showsway === 'true'} iterationCount="1">
                    {children}
                </SwayAnimation>
            </g>
            <defs>
                <clipPath id={uuid_1}>
                    <rect width="208" height="208" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default BasePlant
