import { CircularProgress, Link } from '@mui/material'
import { claimAchievement } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { CurrentAchievementProgress } from '../../../../hooks/useAchievementProgressions/useAchievementProgressions'

const messages = defineMessages({
    claim: {
        defaultMessage: 'Claim',
        description: 'claim button'
    },
    claimAll: {
        defaultMessage: 'Claim All',
        description: 'claim all button'
    }
})

export type ClaimProps = {
    currentAchievementProgress: CurrentAchievementProgress
}

const Claim: React.FC<ClaimProps> = ({ currentAchievementProgress }) => {
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const onClaim = useCallback(async () => {
        try {
            setLoading(true)
            await claimAchievement(
                currentAchievementProgress.id,
                currentAchievementProgress.unclaimedAchievementLevels
            )
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }, [
        currentAchievementProgress.id,
        currentAchievementProgress.unclaimedAchievementLevels
    ])

    if (loading) {
        return <CircularProgress size={20} color="accent" />
    } else {
        return (
            <Link href="javascript:void(0)" onClick={onClaim}>
                <CoreTypography
                    variant="body1"
                    color="primary.main"
                    whiteSpace="nowrap"
                >
                    {currentAchievementProgress.unclaimedAchievementLevels
                        .length > 1
                        ? formatMessage(messages.claimAll)
                        : formatMessage(messages.claim)}
                </CoreTypography>
            </Link>
        )
    }
}

export default Claim
