import { useCallback, useMemo } from 'react'
import { PlantIcons } from '../../../enums/plantIcons'
import { PlantType } from '../../enums/plantTypes'
import {
    ProductInfo,
    UserPlantInfo
} from '../../../graphql/generated/autogenerated'
import {
    advanced1,
    advanced10,
    advanced11,
    advanced12,
    advanced13,
    advanced14,
    advanced15,
    advanced2,
    advanced3,
    advanced4,
    advanced5,
    advanced6,
    advanced7,
    advanced8,
    advanced9,
    advancedRare1,
    advancedRare10,
    advancedRare11,
    advancedRare12,
    advancedRare13,
    advancedRare14,
    advancedRare15,
    advancedRare2,
    advancedRare3,
    advancedRare4,
    advancedRare5,
    advancedRare6,
    advancedRare7,
    advancedRare8,
    advancedRare9,
    beginner1,
    beginner2,
    beginner3,
    beginner4,
    beginner5,
    beginnerRare1,
    beginnerRare2,
    beginnerRare3,
    beginnerRare4,
    beginnerRare5,
    intermediate1,
    intermediate10,
    intermediate2,
    intermediate3,
    intermediate4,
    intermediate5,
    intermediate6,
    intermediate7,
    intermediate8,
    intermediate9,
    intermediateRare1,
    intermediateRare10,
    intermediateRare2,
    intermediateRare3,
    intermediateRare4,
    intermediateRare5,
    intermediateRare6,
    intermediateRare7,
    intermediateRare8,
    intermediateRare9,
    seed,
    seedless,
    novafutura,
    nutrivora1,
    nutrivora2,
    nutrivora3,
    nutrivora4,
    nutrivora5,
    nutrivora6,
    nutrivora7,
    nutrivora8,
    nutrivora9,
    nutrivora10,
    nutrivora11,
    nutrivora12,
    nutrivora13,
    nutrivora14,
    nutrivora15,
    resilifloraRare1,
    resilifloraRare2,
    resilifloraRare3,
    resilifloraRare4,
    resilifloraRare5,
    resilifloraRare6,
    resilifloraRare7,
    resilifloraRare8,
    resilifloraRare9,
    audaciaflora1,
    audaciaflora2,
    audaciaflora3,
    audaciaflora4,
    audaciaflora5,
    audaciaflora6,
    audaciaflora7,
    audaciaflora8,
    audaciaflora9,
    audaciaflora10,
    referralTrophy,
    revalesca1,
    revalesca2,
    revalesca3,
    revalesca4,
    revalesca5,
    revalesca6,
    revalesca7,
    revalesca8,
    revalesca9,
    revalesca10,
    bonvira1,
    bonvira2,
    bonvira3,
    bonvira4,
    bonvira5,
    bonvira6,
    bonvira7,
    bonvira8,
    bonvira9,
    bonvira10,
    cresfolia1,
    cresfolia2,
    cresfolia3,
    cresfolia4,
    cresfolia5,
    cresfolia6,
    cresfolia7,
    cresfolia8,
    cresfolia9,
    cresfolia10,
    fortituda1,
    fortituda2,
    fortituda3,
    fortituda4,
    fortituda5,
    fortituda6,
    fortituda7,
    fortituda8,
    fortituda9,
    fortituda10,
    empty1,
    empty2,
    empty3,
    empty4,
    harmonia1,
    harmonia2,
    harmonia3,
    harmonia4,
    harmonia5,
    harmonia6,
    harmonia7,
    harmonia8,
    harmonia9,
    harmonia10,
    serenita1,
    serenita2,
    serenita3,
    serenita4,
    serenita5,
    solaflora1,
    solaflora2,
    solaflora3,
    solaflora4,
    solaflora5,
    vitara1,
    vitara2,
    vitara3,
    vitara4,
    vitara5,
    vitara6,
    vitara7,
    vitara8,
    vitara9,
    vitara10,
    personalResetTrophy
} from '../../components/icons'
import resilifloraRare10 from '../../components/icons/plants/resilifloraRare/resilifloraRare10'

export const usePlantIcons = () => {
    const plantIcons = useMemo(
        () => ({
            // Trophies
            [PlantIcons.ReferralTrophyPlant]: referralTrophy,
            [PlantIcons.PersonalResetTrophyPlant]: personalResetTrophy,
            // Plants
            [PlantIcons.Seed]: seed,
            [PlantIcons.Beginner_1]: beginner1,
            [PlantIcons.Beginner_2]: beginner2,
            [PlantIcons.Beginner_3]: beginner3,
            [PlantIcons.Beginner_4]: beginner4,
            [PlantIcons.Beginner_5]: beginner5,
            [PlantIcons.Beginner_Rare_1]: beginnerRare1,
            [PlantIcons.Beginner_Rare_2]: beginnerRare2,
            [PlantIcons.Beginner_Rare_3]: beginnerRare3,
            [PlantIcons.Beginner_Rare_4]: beginnerRare4,
            [PlantIcons.Beginner_Rare_5]: beginnerRare5,
            [PlantIcons.Intermediate_1]: intermediate1,
            [PlantIcons.Intermediate_2]: intermediate2,
            [PlantIcons.Intermediate_3]: intermediate3,
            [PlantIcons.Intermediate_4]: intermediate4,
            [PlantIcons.Intermediate_5]: intermediate5,
            [PlantIcons.Intermediate_6]: intermediate6,
            [PlantIcons.Intermediate_7]: intermediate7,
            [PlantIcons.Intermediate_8]: intermediate8,
            [PlantIcons.Intermediate_9]: intermediate9,
            [PlantIcons.Intermediate_10]: intermediate10,
            [PlantIcons.Intermediate_Rare_1]: intermediateRare1,
            [PlantIcons.Intermediate_Rare_2]: intermediateRare2,
            [PlantIcons.Intermediate_Rare_3]: intermediateRare3,
            [PlantIcons.Intermediate_Rare_4]: intermediateRare4,
            [PlantIcons.Intermediate_Rare_5]: intermediateRare5,
            [PlantIcons.Intermediate_Rare_6]: intermediateRare6,
            [PlantIcons.Intermediate_Rare_7]: intermediateRare7,
            [PlantIcons.Intermediate_Rare_8]: intermediateRare8,
            [PlantIcons.Intermediate_Rare_9]: intermediateRare9,
            [PlantIcons.Intermediate_Rare_10]: intermediateRare10,
            [PlantIcons.Advanced_1]: advanced1,
            [PlantIcons.Advanced_2]: advanced2,
            [PlantIcons.Advanced_3]: advanced3,
            [PlantIcons.Advanced_4]: advanced4,
            [PlantIcons.Advanced_5]: advanced5,
            [PlantIcons.Advanced_6]: advanced6,
            [PlantIcons.Advanced_7]: advanced7,
            [PlantIcons.Advanced_8]: advanced8,
            [PlantIcons.Advanced_9]: advanced9,
            [PlantIcons.Advanced_10]: advanced10,
            [PlantIcons.Advanced_11]: advanced11,
            [PlantIcons.Advanced_12]: advanced12,
            [PlantIcons.Advanced_13]: advanced13,
            [PlantIcons.Advanced_14]: advanced14,
            [PlantIcons.Advanced_15]: advanced15,
            [PlantIcons.Advanced_Rare_1]: advancedRare1,
            [PlantIcons.Advanced_Rare_2]: advancedRare2,
            [PlantIcons.Advanced_Rare_3]: advancedRare3,
            [PlantIcons.Advanced_Rare_4]: advancedRare4,
            [PlantIcons.Advanced_Rare_5]: advancedRare5,
            [PlantIcons.Advanced_Rare_6]: advancedRare6,
            [PlantIcons.Advanced_Rare_7]: advancedRare7,
            [PlantIcons.Advanced_Rare_8]: advancedRare8,
            [PlantIcons.Advanced_Rare_9]: advancedRare9,
            [PlantIcons.Advanced_Rare_10]: advancedRare10,
            [PlantIcons.Advanced_Rare_11]: advancedRare11,
            [PlantIcons.Advanced_Rare_12]: advancedRare12,
            [PlantIcons.Advanced_Rare_13]: advancedRare13,
            [PlantIcons.Advanced_Rare_14]: advancedRare14,
            [PlantIcons.Advanced_Rare_15]: advancedRare15,
            [PlantIcons.Resiflora_Rare_1]: resilifloraRare1,
            [PlantIcons.Resiflora_Rare_2]: resilifloraRare2,
            [PlantIcons.Resiflora_Rare_3]: resilifloraRare3,
            [PlantIcons.Resiflora_Rare_4]: resilifloraRare4,
            [PlantIcons.Resiflora_Rare_5]: resilifloraRare5,
            [PlantIcons.Resiflora_Rare_6]: resilifloraRare6,
            [PlantIcons.Resiflora_Rare_7]: resilifloraRare7,
            [PlantIcons.Resiflora_Rare_8]: resilifloraRare8,
            [PlantIcons.Resiflora_Rare_9]: resilifloraRare9,
            [PlantIcons.Resiflora_Rare_10]: resilifloraRare10,
            [PlantIcons.Nutrivora_1]: nutrivora1,
            [PlantIcons.Nutrivora_2]: nutrivora2,
            [PlantIcons.Nutrivora_3]: nutrivora3,
            [PlantIcons.Nutrivora_4]: nutrivora4,
            [PlantIcons.Nutrivora_5]: nutrivora5,
            [PlantIcons.Nutrivora_6]: nutrivora6,
            [PlantIcons.Nutrivora_7]: nutrivora7,
            [PlantIcons.Nutrivora_8]: nutrivora8,
            [PlantIcons.Nutrivora_9]: nutrivora9,
            [PlantIcons.Nutrivora_10]: nutrivora10,
            [PlantIcons.Nutrivora_11]: nutrivora11,
            [PlantIcons.Nutrivora_12]: nutrivora12,
            [PlantIcons.Nutrivora_13]: nutrivora13,
            [PlantIcons.Nutrivora_14]: nutrivora14,
            [PlantIcons.Nutrivora_15]: nutrivora15,
            [PlantIcons.Novafutura]: novafutura,
            [PlantIcons.Revalesca_1]: revalesca1,
            [PlantIcons.Revalesca_2]: revalesca2,
            [PlantIcons.Revalesca_3]: revalesca3,
            [PlantIcons.Revalesca_4]: revalesca4,
            [PlantIcons.Revalesca_5]: revalesca5,
            [PlantIcons.Revalesca_6]: revalesca6,
            [PlantIcons.Revalesca_7]: revalesca7,
            [PlantIcons.Revalesca_8]: revalesca8,
            [PlantIcons.Revalesca_9]: revalesca9,
            [PlantIcons.Revalesca_10]: revalesca10,
            [PlantIcons.Audaciaflora_1]: audaciaflora1,
            [PlantIcons.Audaciaflora_2]: audaciaflora2,
            [PlantIcons.Audaciaflora_3]: audaciaflora3,
            [PlantIcons.Audaciaflora_4]: audaciaflora4,
            [PlantIcons.Audaciaflora_5]: audaciaflora5,
            [PlantIcons.Audaciaflora_6]: audaciaflora6,
            [PlantIcons.Audaciaflora_7]: audaciaflora7,
            [PlantIcons.Audaciaflora_8]: audaciaflora8,
            [PlantIcons.Audaciaflora_9]: audaciaflora9,
            [PlantIcons.Audaciaflora_10]: audaciaflora10,
            [PlantIcons.Solaflora_1]: solaflora1,
            [PlantIcons.Solaflora_2]: solaflora2,
            [PlantIcons.Solaflora_3]: solaflora3,
            [PlantIcons.Solaflora_4]: solaflora4,
            [PlantIcons.Solaflora_5]: solaflora5,
            [PlantIcons.Serenita_1]: serenita1,
            [PlantIcons.Serenita_2]: serenita2,
            [PlantIcons.Serenita_3]: serenita3,
            [PlantIcons.Serenita_4]: serenita4,
            [PlantIcons.Serenita_5]: serenita5,
            [PlantIcons.Bonvira_1]: bonvira1,
            [PlantIcons.Bonvira_2]: bonvira2,
            [PlantIcons.Bonvira_3]: bonvira3,
            [PlantIcons.Bonvira_4]: bonvira4,
            [PlantIcons.Bonvira_5]: bonvira5,
            [PlantIcons.Bonvira_6]: bonvira6,
            [PlantIcons.Bonvira_7]: bonvira7,
            [PlantIcons.Bonvira_8]: bonvira8,
            [PlantIcons.Bonvira_9]: bonvira9,
            [PlantIcons.Bonvira_10]: bonvira10,
            [PlantIcons.Harmonia_1]: harmonia1,
            [PlantIcons.Harmonia_2]: harmonia2,
            [PlantIcons.Harmonia_3]: harmonia3,
            [PlantIcons.Harmonia_4]: harmonia4,
            [PlantIcons.Harmonia_5]: harmonia5,
            [PlantIcons.Harmonia_6]: harmonia6,
            [PlantIcons.Harmonia_7]: harmonia7,
            [PlantIcons.Harmonia_8]: harmonia8,
            [PlantIcons.Harmonia_9]: harmonia9,
            [PlantIcons.Harmonia_10]: harmonia10,
            [PlantIcons.Cresfolia_1]: cresfolia1,
            [PlantIcons.Cresfolia_2]: cresfolia2,
            [PlantIcons.Cresfolia_3]: cresfolia3,
            [PlantIcons.Cresfolia_4]: cresfolia4,
            [PlantIcons.Cresfolia_5]: cresfolia5,
            [PlantIcons.Cresfolia_6]: cresfolia6,
            [PlantIcons.Cresfolia_7]: cresfolia7,
            [PlantIcons.Cresfolia_8]: cresfolia8,
            [PlantIcons.Cresfolia_9]: cresfolia9,
            [PlantIcons.Cresfolia_10]: cresfolia10,
            [PlantIcons.Vitara_1]: vitara1,
            [PlantIcons.Vitara_2]: vitara2,
            [PlantIcons.Vitara_3]: vitara3,
            [PlantIcons.Vitara_4]: vitara4,
            [PlantIcons.Vitara_5]: vitara5,
            [PlantIcons.Vitara_6]: vitara6,
            [PlantIcons.Vitara_7]: vitara7,
            [PlantIcons.Vitara_8]: vitara8,
            [PlantIcons.Vitara_9]: vitara9,
            [PlantIcons.Vitara_10]: vitara10,
            [PlantIcons.Fortituda_1]: fortituda1,
            [PlantIcons.Fortituda_2]: fortituda2,
            [PlantIcons.Fortituda_3]: fortituda3,
            [PlantIcons.Fortituda_4]: fortituda4,
            [PlantIcons.Fortituda_5]: fortituda5,
            [PlantIcons.Fortituda_6]: fortituda6,
            [PlantIcons.Fortituda_7]: fortituda7,
            [PlantIcons.Fortituda_8]: fortituda8,
            [PlantIcons.Fortituda_9]: fortituda9,
            [PlantIcons.Fortituda_10]: fortituda10,
            [PlantIcons.Empty_1]: empty1,
            [PlantIcons.Empty_2]: empty2,
            [PlantIcons.Empty_3]: empty3,
            [PlantIcons.Empty_4]: empty4
        }),
        []
    )

    const getPlantIcon = useCallback(
        (plant: UserPlantInfo) => {
            let selectedPlantIcon

            if (!plant) {
                return seedless
            }

            switch (plant.productUpc) {
                // Trophies
                case PlantType.referralTrophyPlant:
                    selectedPlantIcon =
                        plantIcons[PlantIcons.ReferralTrophyPlant]
                    break
                case PlantType.personalResetTrophyPlant:
                    selectedPlantIcon =
                        plantIcons[PlantIcons.PersonalResetTrophyPlant]
                    break
                // Plants
                case PlantType.nutrivora:
                    selectedPlantIcon =
                        plantIcons[`nutrivora${plant.progressSteps}`]
                    break
                case PlantType.resilifloraRare:
                    selectedPlantIcon =
                        plantIcons[`resiliflora.rare${plant.progressSteps}`]
                    break
                case PlantType.advanced:
                    selectedPlantIcon =
                        plantIcons[`advanced${plant.progressSteps}`]
                    break
                case PlantType.advancedRare:
                    selectedPlantIcon =
                        plantIcons[`advanced.rare${plant.progressSteps}`]
                    break
                case PlantType.intermediate:
                    selectedPlantIcon =
                        plantIcons[`intermediate${plant.progressSteps}`]
                    break
                case PlantType.intermediateRare:
                    selectedPlantIcon =
                        plantIcons[`intermediate.rare${plant.progressSteps}`]
                    break
                case PlantType.beginner:
                    selectedPlantIcon =
                        plantIcons[`beginner${plant.progressSteps}`]
                    break
                case PlantType.beginnerRare:
                    selectedPlantIcon =
                        plantIcons[`beginner.rare${plant.progressSteps}`]
                    break
                case PlantType.novafutura:
                    selectedPlantIcon = plantIcons[PlantIcons.Novafutura]
                    break
                case PlantType.revalesca:
                    selectedPlantIcon =
                        plantIcons[`revalesca${plant.progressSteps}`]
                    break
                case PlantType.audaciaflora:
                    selectedPlantIcon =
                        plantIcons[`audaciaflora${plant.progressSteps}`]
                    break
                case PlantType.solaflora:
                    selectedPlantIcon =
                        plantIcons[`solaflora${plant.progressSteps}`]
                    break
                case PlantType.serenita:
                    selectedPlantIcon =
                        plantIcons[`serenita${plant.progressSteps}`]
                    break
                case PlantType.bonvira:
                    selectedPlantIcon =
                        plantIcons[`bonvira${plant.progressSteps}`]
                    break
                case PlantType.harmonia:
                    selectedPlantIcon =
                        plantIcons[`harmonia${plant.progressSteps}`]
                    break
                case PlantType.cresfolia:
                    selectedPlantIcon =
                        plantIcons[`cresfolia${plant.progressSteps}`]
                    break
                case PlantType.vitara:
                    selectedPlantIcon =
                        plantIcons[`vitara${plant.progressSteps}`]
                    break
                case PlantType.fortituda:
                    selectedPlantIcon =
                        plantIcons[`fortituda${plant.progressSteps}`]
                    break
                default:
                    selectedPlantIcon = seed
            }

            if (!selectedPlantIcon) {
                return seed
            }

            return selectedPlantIcon
        },
        [plantIcons]
    )

    const getPlantProductIcon = useCallback((product: ProductInfo) => {
        switch (product.upc) {
            case PlantType.novafutura:
                return novafutura
            case PlantType.nutrivora:
                return nutrivora15
            case PlantType.resilifloraRare:
                return resilifloraRare10
            case PlantType.advanced:
                return advanced15
            case PlantType.advancedRare:
                return advancedRare15
            case PlantType.intermediate:
                return intermediate10
            case PlantType.intermediateRare:
                return intermediateRare10
            case PlantType.beginner:
                return beginner5
            case PlantType.beginnerRare:
                return beginnerRare5
            default:
                return seedless
        }
    }, [])

    const getEmptyStatePlant = useCallback((index = 0) => {
        switch (index % 4) {
            case 0:
                return empty1
            case 1:
                return empty2
            case 2:
                return empty3
            case 3:
                return empty4
            default:
                return empty1
        }
    }, [])

    return { getPlantIcon, getPlantProductIcon, getEmptyStatePlant }
}
