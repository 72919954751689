import { ClassNames, keyframes } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import React, { ReactElement, memo, useCallback, useMemo } from 'react'

export interface SparkleAnimationProps {
    duration?: number
    iteration?: any
    children: ReactElement<any, any>
}

const SparkleAnimation: React.FC<SparkleAnimationProps> = ({
    duration = 1.5,
    iteration = 1,
    children
}) => {
    const prefersReducedMotion = useMediaQuery(
        '(prefers-reduced-motion: reduce)'
    )

    const sparkleAnimation = keyframes({
        '0%': { opacity: 1, transform: 'scale(1)' },
        '10%': { opacity: 0, transform: 'scale(1)' },
        '20%': { opacity: 0, transform: 'scale(0)' },
        '30%': { opacity: 1, transform: 'scale(0.5)' },
        '40%': { opacity: 0, transform: 'scale(1)' },
        '50%': { opacity: 0, transform: 'scale(0)' },
        '60%': { opacity: 1, transform: 'scale(0.5)' },
        '70%': { opacity: 0, transform: 'scale(1)' },
        '80%': { opacity: 0, transform: 'scale(0)' },
        '100%': { opacity: 1, transform: 'scale(1)' }
    })

    const infiniteSparkleAnimation = keyframes({
        '33%': { opacity: 0, transform: 'scale(0)' },
        '66%': { opacity: 1, transform: 'scale(0.5)' },
        '100%': { opacity: 0, transform: 'scale(1)' }
    })

    const delay = Math.floor(Math.random() * (1250 - 250 + 1) + 250)

    const { activeAnimation, initialOpacity } = useMemo(
        () =>
            iteration === 'infinite'
                ? {
                      activeAnimation: infiniteSparkleAnimation,
                      initialOpacity: 0
                  }
                : {
                      activeAnimation: sparkleAnimation,
                      initialOpacity: 1
                  },

        [infiniteSparkleAnimation, sparkleAnimation, iteration]
    )

    const sparkleAnimationStyles = useMemo(() => {
        return {
            animation: activeAnimation,
            animationName: activeAnimation.name,
            animationDirection: 'normal',
            animationDuration: `${duration}s`,
            animationTimingFunction: 'ease-in',
            animationIterationCount: iteration,
            animationDelay: `${delay}ms`,
            animationPlayState: !prefersReducedMotion ? 'running' : 'paused',
            transformOrigin: '50% 50%',
            transformBox: 'fill-box',
            opacity: initialOpacity
        }
    }, [
        activeAnimation,
        iteration,
        initialOpacity,
        duration,
        delay,
        prefersReducedMotion
    ])

    const renderChildElement = useCallback(
        (css, cx) => {
            return React.cloneElement(children, {
                className: cx(css(sparkleAnimationStyles))
            })
        },
        [children, sparkleAnimationStyles]
    )

    return (
        <ClassNames>{({ css, cx }) => renderChildElement(css, cx)}</ClassNames>
    )
}

export default memo(SparkleAnimation)
