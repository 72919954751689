import { Badge, useTheme } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { usePreviousAchievementsV3Progress } from '../../../../hooks'
import { AchievementsCenterMenuWithPlantProvider } from '../AchievementsCenterMenu'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { promptSelectedDefaultValues } from '../../../../utils/Avo/defaultValues'

const messages = defineMessages({
    openAchievementCenter: {
        defaultMessage: 'Open achievement center popup',
        description:
            'description for the visually-impaired of the button action'
    },
    closeAriaLabel: {
        defaultMessage: 'close',
        description: 'description for the visually-impaired of the close button'
    }
})

const AchievementCounterButton: React.FC = () => {
    const { palette } = useTheme()
    const { formatMessage } = useIntl()
    const [openAchievementCenter, setOpenAchievementCenter] = useState(false)
    const { newAchievementsCount, setCurrentProgress } =
        usePreviousAchievementsV3Progress()

    const handleOpen = useCallback(() => {
        setOpenAchievementCenter(true)
        Avo.promptSelected({
            ...promptSelectedDefaultValues,
            featureType: 'achievement',
            activityType: 'achievements_opened',
            notificationCount: newAchievementsCount
        })
    }, [newAchievementsCount])

    const handleClose = useCallback(() => {
        setOpenAchievementCenter(false)
    }, [])

    useEffect(() => {
        window.addEventListener('popstate', handleClose)
        // On unmount of this component cleanup and remove the listener
        return () => {
            window.removeEventListener('popstate', handleClose)
        }
    }, [handleClose])

    // Only set the current progress when the achievement center is closed after being opened
    const openAchievementCenterPrev = useRef(openAchievementCenter)
    useEffect(() => {
        if (openAchievementCenterPrev.current && !openAchievementCenter) {
            setCurrentProgress()
        }

        openAchievementCenterPrev.current = openAchievementCenter
    }, [openAchievementCenter, setCurrentProgress])

    return (
        <>
            <IconButton
                id="achievements-center-button"
                aria-label={formatMessage(messages.openAchievementCenter)}
                aria-haspopup="true"
                aria-expanded={openAchievementCenter}
                onClick={handleOpen}
                color="secondary"
                variant="contained"
                size="large"
                sx={{
                    minHeight: '44px'
                }}
            >
                <Badge
                    data-testid="notification-badge"
                    invisible={newAchievementsCount < 1}
                    color="secondary"
                    variant="dot"
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    sx={{
                        '& .MuiBadge-dot': {
                            top: -4,
                            width: 12,
                            right: -1,
                            height: 12,
                            borderRadius: '50%',
                            backgroundColor: palette.journeys.connect.main
                        }
                    }}
                >
                    <LeafIcon icon={'leaf'} iconStyle="solid" />
                </Badge>
            </IconButton>

            <AchievementsCenterMenuWithPlantProvider
                isOpen={openAchievementCenter}
                handleOnClose={handleClose}
            />
        </>
    )
}

export default AchievementCounterButton
