import React, { Children, ReactElement, memo } from 'react'
import SparkleAnimation from './SparkleAnimation'

export interface SparkleAnimationIteratorProps {
    duration?: number
    iteration?: any
    children: ReactElement<any, any>
}

const SparkleAnimationIterator: React.FC<SparkleAnimationIteratorProps> = ({
    duration = 1.5,
    iteration = 1,
    children
}) => {
    return (
        <g>
            {children?.props?.children?.map((child, index) => (
                <SparkleAnimation
                    duration={duration}
                    iteration={iteration}
                    key={index}
                >
                    {child}
                </SparkleAnimation>
            ))}
        </g>
    )
}

export default memo(SparkleAnimationIterator)
