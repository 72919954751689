import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { GenericErrorMessages } from '../../../errors/generic'

export const useErrorHandling = () => {
    const intl = useIntl()
    const generateErrorNotification = useCallback(
        (errorTypes, code) => {
            const color: 'error' | 'success' | 'info' | 'warning' = 'warning'
            const title = intl.formatMessage(
                GenericErrorMessages.thereWasAnError
            )
            let message = intl.formatMessage(GenericErrorMessages.failedToFetch)
            for (const property in errorTypes) {
                if (
                    Object.prototype.hasOwnProperty.call(errorTypes, property)
                ) {
                    const error = errorTypes[property]
                    if (error.code === code) {
                        message = intl.formatMessage(error.message)
                    }
                }
            }
            return {
                title: title,
                message: message,
                error: code,
                color: color
            }
        },
        [intl]
    )

    return { generateErrorNotification }
}

export default useErrorHandling
