import { useMemo } from 'react'
import { AchievementStates } from '../../enums/achievementStates'
import { UserProgressDetail } from '../withAchievementsProvider'

export const useAchievementState = (progress: UserProgressDetail) =>
    useMemo(() => {
        if (progress.claimedAt) {
            return AchievementStates.complete
        } else if (progress.completedAt) {
            return AchievementStates.claim
        }
        return AchievementStates.locked
    }, [progress])

export default useAchievementState
