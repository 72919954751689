import { Image } from '../../../shared'

const IMG_URL =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/42ce3b40-d17a-428e-1979-1d1f4cbe0f00/cms'

const PlantsImage = () => {
    return <Image src={IMG_URL} />
}

export default PlantsImage
