import { Box, Stack } from '@mui/material'
import {
    BurstNotification,
    CoreTypography,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import debounce from 'lodash/debounce'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useCountUp } from 'react-countup'
import { useIntl } from 'react-intl'
import { HiddenDescription } from '../../../../shared'
import { AchievementAccessibilityDescriptions } from '../../../../shared/enums/achievementAccessibilityDescriptions'
import {
    useAchievementAccessibilityDescriptions,
    useActiveRefDimensions,
    useClaimTokens
} from '../../../../shared/hooks'

export type TokensCardProps = {
    isLarge?: boolean
}

const countKey = 'TOKEN'

const Tokens: React.FC<TokensCardProps> = ({ isLarge }) => {
    const theme = useTheme()
    const { formatNumber } = useIntl()
    const descriptions = useAchievementAccessibilityDescriptions()
    const { tokenCount } = useClaimTokens()
    const [initialTokenCount, _] = useState(tokenCount)
    const [priorTokenCount, setPriorTokenCount] = useState(tokenCount)
    const [showBurst, setShowBurst] = useState(false)
    const { activeComponentWidth, activeComponentHeight, getRef } =
        useActiveRefDimensions(countKey)

    const countUpRef = React.useRef(null)

    const debouncedBurst = debounce(() => {
        setShowBurst(true)
    }, 1332)

    const { update } = useCountUp({
        ref: countUpRef,
        start: initialTokenCount,
        end: tokenCount,
        delay: 0.666,
        duration: 0.666,
        formattingFn: useCallback(
            (value: any) => {
                return formatNumber(value, {
                    maximumSignificantDigits: value > 1000 ? 2 : 3,
                    notation: 'compact',
                    compactDisplay: 'short'
                })
            },
            [formatNumber]
        ),
        onUpdate: useCallback(() => {
            if (priorTokenCount < tokenCount && !showBurst) {
                debouncedBurst()
            }
        }, [debouncedBurst, priorTokenCount, showBurst, tokenCount])
    })

    useEffect(() => {
        setShowBurst(false)
        update(tokenCount)
        if (tokenCount !== priorTokenCount) {
            setPriorTokenCount(tokenCount)
        }
    }, [setShowBurst, update, tokenCount, priorTokenCount])

    const BurstWrapper = useMemo(
        () => (showBurst ? BurstNotification : Box),
        [showBurst]
    )

    return (
        <Box
            width={'100%'}
            data-testid="tokenCount-card"
            maxWidth={isLarge ? 165 : 110}
            minWidth={isLarge ? 165 : 110}
            position="relative"
        >
            <HiddenDescription
                id="tokenCount-card-description"
                component="output"
                aria-live="polite"
                aria-atomic="true"
            >
                {descriptions[AchievementAccessibilityDescriptions.TokenCount]({
                    tokenCount
                })}
            </HiddenDescription>
            <Stack minWidth={theme.spacing(activeComponentWidth)}>
                <Box
                    minHeight={theme.spacing(
                        activeComponentHeight ? activeComponentHeight : 4.75
                    )}
                >
                    <Box
                        position="absolute"
                        ref={(element) => getRef(element, countKey)}
                    >
                        <Stack>
                            <Stack
                                position="absolute"
                                top={0}
                                left={0}
                                right={0}
                                bottom={0}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <BurstWrapper
                                    {...(showBurst && {
                                        show: true,
                                        alwaysVisible: true,
                                        skipAnimation: false,
                                        size: 50,
                                        duration: 50,
                                        onCompleteAction: () => {
                                            setShowBurst(false)
                                        }
                                    })}
                                />
                            </Stack>
                            <CoreTypography
                                customVariant={isLarge ? 'stat2' : 'stat3'}
                                as={Box}
                            >
                                <Stack ref={countUpRef} />
                            </CoreTypography>
                        </Stack>
                    </Box>
                </Box>
            </Stack>
        </Box>
    )
}

export default memo(Tokens)
