import {
    fetchAndStoreAchievements,
    fetchAndStoreGarden,
    fetchAndStoreWallet,
    useAppSelector,
    useIsFeatureEnabled
} from '@thriveglobal/thrive-web-core'
import { ComponentType, useCallback, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    AchievementLevel,
    AchievementV2
} from '../../../graphql/generated/autogenerated'
import { AchievementTypes } from '../../enums/achievementTypes'
import AchievementsProviderContext, {
    MappedAchievement
} from './AchievementsProviderContext'

const messages = defineMessages({
    achievementProgressTitle: {
        defaultMessage: 'Level {achievementLevel} - {achievementTitle}',
        description:
            'description of the current level for the achievement and its title, e.g. Level 4 - MicroStepper'
    }
})

const stressAchievementUpc = 'achievement.journey.stress'

export type AchievementLevelInfo = {
    level: AchievementLevel
    achievement: AchievementV2
}

export default function withAchievementsProvider<Props>(
    Component: ComponentType<Props>
) {
    return (props: Props): JSX.Element => {
        const intl = useIntl()
        const { achievementState, subscriptionId } = useAppSelector(
            (state) => ({
                achievementState: state?.achievement,
                subscriptionId: (state?.auth as any)?.subscriptionId as string
            })
        )
        const [manuallyClaimed, setManuallyClaimed] = useState<string[]>([])

        const showReferralAchievement = useIsFeatureEnabled(
            'showReferrals',
            false,
            true
        )

        const mappedAchievements = useMemo(() => {
            const achievements = achievementState.achievements
            const stateProgress = achievementState.progress

            return achievements
                .filter(
                    (achievement) =>
                        achievement.actionType !==
                            AchievementTypes.referralCompleted ||
                        showReferralAchievement
                )
                .map((achievement) => {
                    const mappedAchievement = {} as MappedAchievement
                    const achievementProgress = stateProgress.find(
                        (progress) => progress.achievementId === achievement.id
                    )
                    mappedAchievement.achievementInfo = achievement
                    mappedAchievement.userProgress =
                        achievementProgress?.levels?.map(
                            (progress: any, index) => {
                                const previousLevel =
                                    achievementProgress?.levels[index - 1]

                                return {
                                    achievementId: achievement.id,
                                    claimedAt: progress.claimedAt,
                                    completedAt: progress.completedAt,
                                    level: progress.level,
                                    total: previousLevel
                                        ? progress.total - previousLevel.total
                                        : progress.total,
                                    currentProgress:
                                        previousLevel &&
                                        achievement.actionType !==
                                            AchievementTypes.journeyComplete &&
                                        achievement.actionType !==
                                            AchievementTypes.referralCompleted
                                            ? achievementProgress.progress -
                                              previousLevel.total
                                            : achievementProgress.progress,
                                    title: intl.formatMessage(
                                        messages.achievementProgressTitle,
                                        {
                                            achievementLevel: progress.level,
                                            achievementTitle: achievement.title
                                        }
                                    )
                                }
                            }
                        )

                    return mappedAchievement
                })
        }, [achievementState, intl, showReferralAchievement])

        const sortByCompletedAt = (
            a: AchievementLevelInfo,
            b: AchievementLevelInfo
        ): number =>
            new Date(b.level.completedAt).getTime() -
            new Date(a.level.completedAt).getTime()

        const claimedAchievements = useMemo(() => {
            const getAchievement = (id) =>
                achievementState?.achievements?.find((a) => a.id === id)

            return achievementState?.progress
                ?.reduce((acc, p) => {
                    const filtered = p.levels.filter((p) => !!p.claimedAt)
                    const mapped = filtered.map((l) => {
                        return {
                            achievement: getAchievement(p.achievementId),
                            level: l
                        } as AchievementLevelInfo
                    })

                    return acc.concat(mapped)
                }, [])
                .sort(sortByCompletedAt)
        }, [achievementState])

        const fetchAchievements = useCallback(() => {
            fetchAndStoreWallet()
            fetchAndStoreAchievements()
            fetchAndStoreGarden()
        }, [])

        const state = useMemo(
            () => ({
                achievements: mappedAchievements,
                claimedAchievements: claimedAchievements,
                manuallyClaimed,
                setManuallyClaimed,
                fetchAchievements
            }),
            [
                mappedAchievements,
                claimedAchievements,
                manuallyClaimed,
                setManuallyClaimed,
                fetchAchievements
            ]
        )

        return (
            <AchievementsProviderContext.Provider value={state}>
                <Component {...props} />
            </AchievementsProviderContext.Provider>
        )
    }
}
