import cloneDeep from 'lodash/cloneDeep'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'
import {
    AchievementProgress,
    AchievementV2
} from '../../graphql/generated/autogenerated'
import { AchievementUpcs } from '../../shared/enums'
import { isJourneyRelatedAchievement } from '../../utils'
import { ReactNullValue } from '../../utils/Nulls'

export const useAchievementProgressions = (
    achievements: AchievementV2[],
    progress: AchievementProgress[]
) => {
    return useMemo(() => {
        const clonedProgress = cloneDeep(progress)
        const ap = achievements.map((achievement) => {
            const progressEntry = clonedProgress.find(
                (p) => p.achievementId === achievement.id
            )
            if (achievement.upc === AchievementUpcs.communityBuilder) {
                progressEntry.levels = progressEntry?.levels?.map((l) => ({
                    ...l,
                    level: l.level + 1,
                    total: l.total + 1
                }))
            }
            let currentLevel = 0
            let progressPercentage = 0
            let lastUpdated = ReactNullValue
            const unclaimedIndex = progressEntry?.levels
                .slice()
                .reverse()
                .findIndex((l) => l.completedAt && !l.claimedAt)
            const canClaim = unclaimedIndex > -1
            const hasClaimed = progressEntry?.levels.some((l) => !!l.claimedAt)
            const unclaimedLevel = progressEntry?.levels.slice().reverse()[
                unclaimedIndex
            ]
            const lastLevel =
                progressEntry?.levels[progressEntry.levels.length - 1]
            const secondLastLevel =
                progressEntry?.levels[progressEntry.levels.length - 2]

            if (canClaim) {
                currentLevel = secondLastLevel?.level ?? lastLevel?.level
                lastUpdated = new Date(unclaimedLevel.completedAt)
                progressPercentage = 100
            } else {
                currentLevel = lastLevel?.level
                lastUpdated = new Date(secondLastLevel?.completedAt)
                const percentage =
                    ((progressEntry?.progress - secondLastLevel?.total) /
                        (lastLevel?.total - secondLastLevel?.total)) *
                        100 || 0
                progressPercentage = Math.min(Math.max(percentage, 0), 100)
            }
            return {
                id: achievement.id,
                upc: achievement.upc as AchievementUpcs,
                title: achievement.title,
                unclaimedAchievementLevels: progressEntry?.levels
                    .filter((l) => l.completedAt && !l.claimedAt)
                    .map((l) => l.level),
                isJourneysAchievement: isJourneyRelatedAchievement(
                    achievement.upc
                ),
                lastUpdated,
                hasClaimed,
                canClaim,
                currentLevel,
                progressPercentage
            }
        })
        return orderBy(
            ap,
            [
                'canClaim',
                'isJourneysAchievement',
                'progressPercentage',
                'lastUpdated'
            ],
            ['desc', 'asc', 'desc', 'desc']
        )
    }, [achievements, progress])
}

export type CurrentAchievementProgress = ReturnType<
    typeof useAchievementProgressions
>[0]
