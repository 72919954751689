import { ClassNames, keyframes } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'

export interface JiggleAnimationProps {
    show?: boolean
    iterationCount?: string
    duration?: number
    onAnimationComplete?: () => void
    children: ReactElement<any, any>
}

const JiggleAnimation: React.FC<JiggleAnimationProps> = ({
    show = false,
    iterationCount = 'infinite',
    duration = 1,
    onAnimationComplete,
    children
}) => {
    const prefersReducedMotion = useMediaQuery(
        '(prefers-reduced-motion: reduce)'
    )

    const jiggleAnimation = keyframes({
        '0%': { transform: 'rotate(0deg)' },
        '20%': { transform: 'rotate(-2deg)' },
        '30%': { transform: 'rotate(2deg)' },
        '40%': { transform: 'rotate(-2deg)' },
        '50%': { transform: 'rotate(2deg)' },
        '60%': { transform: 'rotate(-2deg)' },
        '70%': { transform: 'rotate(2deg)' },
        '80%': { transform: 'rotate(-2deg)' },
        '90%': { transform: 'rotate(2deg)' },
        '100%': { transform: 'rotate(0deg)' }
    })

    const jiggleAnimationStyles = useMemo(() => {
        return {
            overflow: 'visible',
            animation: jiggleAnimation,
            animationName: jiggleAnimation.name,
            animationDirection: 'normal',
            animationDuration: `${duration}s`,
            animationTimingFunction: 'ease-in',
            animationIterationCount: iterationCount,
            animationPlayState:
                show && !prefersReducedMotion ? 'running' : 'paused',
            animationFillMode: 'both'
        }
    }, [jiggleAnimation, duration, iterationCount, show, prefersReducedMotion])

    useEffect(() => {
        if (show === true) {
            setTimeout(() => onAnimationComplete?.(), duration * 1000)
        }
    }, [show, onAnimationComplete, duration])

    const renderChildElement = useCallback(
        (css, cx) => {
            return React.cloneElement(children, {
                className: cx(css(jiggleAnimationStyles))
            })
        },
        [children, jiggleAnimationStyles]
    )

    return (
        <ClassNames>{({ css, cx }) => renderChildElement(css, cx)}</ClassNames>
    )
}

export default JiggleAnimation
