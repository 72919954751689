import {
    Box,
    Button,
    Skeleton,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    ToggleButtonGroupProps,
    styled,
    toggleButtonGroupClasses
} from '@mui/material'
import React, { useMemo } from 'react'
import { ProductInfo } from '../../../graphql/generated/autogenerated'
import Plant from '../../../shared/components/features/Garden/Plant/Plant'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { defineMessage, useIntl } from 'react-intl'

const ARIA_LABEL = defineMessage({
    defaultMessage: 'Select a plant',
    description: 'label for plant select toggle buttons'
})

const TOGGLE_BUTTON_SIZES = {
    small: {
        width: 145,
        height: 86
    },
    medium: {
        width: 201,
        height: 149
    }
} as const

type ToggleButtonSizes = keyof typeof TOGGLE_BUTTON_SIZES

type PlantSelectToggleProps = {
    size?: ToggleButtonSizes
    orientation?: ToggleButtonGroupProps['orientation']
    options: [ProductInfo, ProductInfo] | [undefined, undefined]
    value: ProductInfo['upc']
    onChange: (_: unknown, value: ProductInfo['upc']) => void
    onSubmit?: () => void
    loading?: boolean
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
    ({ theme, size }) => ({
        gap: theme.spacing(3),
        [`& .${toggleButtonGroupClasses.grouped}`]: {
            margin: 0,
            width: TOGGLE_BUTTON_SIZES[size].width,
            height: TOGGLE_BUTTON_SIZES[size].height
        },
        [`& .${toggleButtonGroupClasses.grouped}.Mui-selected`]: {
            backgroundColor: theme.palette.secondary.main
        },
        [`& .${toggleButtonGroupClasses.grouped}:hover, & .${toggleButtonGroupClasses.grouped}:focus-visible, & .${toggleButtonGroupClasses.grouped}.Mui-selected:hover`]:
            {
                backgroundColor: theme.palette.secondary.light
            },
        [`& .${toggleButtonGroupClasses.grouped}:not(:first-of-type), & .${toggleButtonGroupClasses.grouped}:first-of-type`]:
            {
                borderRadius: theme.shape.borderRadius,
                borderColor: theme.palette.secondary.dark,
                borderWidth: 1
            }
    })
)

const PlantSelectToggle: React.FC<PlantSelectToggleProps> = ({
    size = 'medium',
    orientation = 'horizontal',
    options = [undefined, undefined],
    value,
    onChange,
    onSubmit,
    loading = false
}) => {
    const { formatMessage } = useIntl()
    const selectedPlant = useMemo(
        () => options.find((option) => option?.upc === value),
        [options, value]
    )
    return (
        <Stack gap={2}>
            <StyledToggleButtonGroup
                size={size}
                value={value}
                exclusive
                onChange={onChange}
                aria-label={formatMessage(ARIA_LABEL)}
                orientation={orientation}
            >
                {options.map((option?: ProductInfo) =>
                    !option || loading ? (
                        <Skeleton
                            variant="circular"
                            width={TOGGLE_BUTTON_SIZES[size].width}
                            height={TOGGLE_BUTTON_SIZES[size].height}
                        />
                    ) : (
                        <ToggleButton
                            disableRipple
                            value={option.upc}
                            key={option.upc}
                            aria-label={option.title}
                            sx={{ borderRadius: 1 }}
                        >
                            <Box position="relative" width="100%" height="100%">
                                <Box position="absolute" bottom={-24}>
                                    <Plant
                                        isPreview
                                        showDirt={false}
                                        showSway={false}
                                        product={option}
                                    />
                                </Box>
                            </Box>
                        </ToggleButton>
                    )
                )}
            </StyledToggleButtonGroup>
            {!!onSubmit && !!selectedPlant && (
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent={
                        selectedPlant === options[0] ? 'flex-start' : 'flex-end'
                    }
                >
                    <Box width={TOGGLE_BUTTON_SIZES[size].width}>
                        <Button
                            color="secondary"
                            variant="contained"
                            fullWidth
                            onClick={onSubmit}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                Grow this plant
                            </CoreTypography>
                        </Button>
                    </Box>
                </Stack>
            )}
        </Stack>
    )
}

export default PlantSelectToggle
