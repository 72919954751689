import { createContext } from 'react'
import { ProductInfo } from '../../../graphql/generated/autogenerated'

export interface ProductList {
    title: string
    products: ProductInfo[]
}
export interface ShopProviderValue {
    plantProducts: ProductList[]
    objectProducts: ProductList[]
    setProducts: (products: ProductInfo[]) => void
    hasActivePlant: boolean
    setHasActivePlant: (active: boolean) => void
}

const defaultContextValue: ShopProviderValue = {
    plantProducts: [],
    objectProducts: [],
    setProducts: () => {},
    hasActivePlant: false,
    setHasActivePlant: () => {}
}

export default createContext(defaultContextValue)
