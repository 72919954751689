import { SvgIcon, SvgIconProps } from '@mui/material'
import { EmptyAnimation } from '../../elements'

export interface WateringCanProps extends SvgIconProps {
    full: boolean
    watering: boolean
    refill: boolean
    onWateringAnimationComplete?: () => void
}

const WateringCan: React.FC<WateringCanProps> = ({
    full,
    watering,
    refill,
    onWateringAnimationComplete,
    ...props
}) => {
    return (
        <SvgIcon
            width="72"
            height="69"
            viewBox="0 0 72 69"
            fill="transparent"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                width: '100%',
                height: '100%',
                maxHeight: 77.14
            }}
            {...props}
        >
            <path
                d="M0.679604 10.2427V12.377C0.679604 12.377 20.2964 17.2363 20.2964 59.097L23.7274 47.8086C23.7274 47.8086 26.1051 18.0212 0.679604 10.2427Z"
                fill={full ? '#F6C344' : '#BFC2C7'}
            />
            <path
                d="M18.5387 59.1747L26.0781 7.5979H51.8704L59.4098 59.1747H18.5387Z"
                fill={full ? '#F6C344' : '#BFC2C7'}
            />
            {full && (
                <path
                    d="M51.9044 28.6887L55.9191 56.1587H22.0257L26.0404 28.6887C28.0459 31.8482 32.6563 31.8482 34.6618 28.6887C36.6672 31.8482 41.2776 31.8482 43.2831 28.6887C45.2885 31.8482 49.8989 31.8482 51.9044 28.6887Z"
                    fill={'#1495F2'}
                />
            )}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.847 15.2251C50.847 15.018 51.0149 14.8501 51.222 14.8501H71.5817L71.6057 15.1994L71.2316 15.2251C71.6057 15.1994 71.6057 15.199 71.6057 15.1994L71.6061 15.2058L71.6073 15.2234L71.6115 15.2916C71.6151 15.3515 71.6201 15.4399 71.626 15.5551C71.6376 15.7855 71.6525 16.1232 71.6655 16.5543C71.6915 17.4166 71.7099 18.653 71.6799 20.153C71.6198 23.1517 71.3661 27.21 70.5897 31.4407C69.8138 35.6685 68.5127 40.0878 66.3471 43.7963C64.1791 47.5088 61.1338 50.5263 56.8713 51.9044C56.6743 51.9681 56.4629 51.86 56.3991 51.6629C56.3354 51.4659 56.4435 51.2545 56.6406 51.1908C60.6809 49.8845 63.5964 47.0194 65.6994 43.4181C67.8047 39.8129 69.0846 35.487 69.852 31.3053C70.6189 27.1265 70.8705 23.1109 70.93 20.138C70.9598 18.6522 70.9415 17.4284 70.9159 16.5769C70.9032 16.1556 70.8887 15.8256 70.8773 15.6001H51.222C51.0149 15.6001 50.847 15.4322 50.847 15.2251Z"
                fill={full ? '#663C00' : '#6B778C'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.6557 24.1627C23.8606 24.1926 24.0025 24.383 23.9725 24.588L18.9098 59.2289C18.8799 59.4338 18.6894 59.5757 18.4845 59.5457C18.2796 59.5158 18.1377 59.3254 18.1677 59.1205L23.2304 24.4795C23.2603 24.2746 23.4508 24.1327 23.6557 24.1627Z"
                fill={full ? '#663C00' : '#6B778C'}
            />
            <path
                d="M55.6401 68.9999H22.3084L18.5387 59.2463H59.4098L55.6401 68.9999Z"
                fill={full ? '#C69D38' : '#A2A6AE'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.25 0.86924C23.3159 0.692358 23.5029 0.591971 23.6867 0.634869L51.9557 7.23278C52.1412 7.27608 52.2648 7.45161 52.243 7.64087C52.2212 7.83013 52.0609 7.97297 51.8704 7.97297H26.0781C26.0112 7.97297 25.9454 7.95504 25.8877 7.92103L25.6738 7.79492C23.3064 6.37109 22.286 3.45904 23.25 0.86924ZM23.8483 1.44274C23.1991 3.61146 24.0962 5.96999 26.0589 7.15136L26.1804 7.22297H48.6139L23.8483 1.44274Z"
                fill={full ? '#663C00' : '#6B778C'}
            />
        </SvgIcon>
    )
}

export default WateringCan
