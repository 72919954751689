import { Box, Fade } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useRef } from 'react'
import { useActiveRefDimensions } from '../../../../hooks'

export type OverlappingComponentsPairProps = {
    primary: React.ReactNode
    secondary: React.ReactNode
    showPrimary: boolean
}

const OverlappingComponentsPair: React.FC<OverlappingComponentsPairProps> = ({
    primary,
    secondary,
    showPrimary
}) => {
    const theme = useTheme()
    const { activeComponentWidth, activeComponentHeight, getRef } =
        useActiveRefDimensions(String(showPrimary))

    return (
        <Box
            data-testid="overlapping-components"
            position="relative"
            width={theme.spacing(activeComponentWidth)}
            height={theme.spacing(activeComponentHeight)}
        >
            <Box
                sx={{ position: 'absolute', right: 0, bottom: 0 }}
                ref={(element) => getRef(element, String(true))}
            >
                <Fade in={showPrimary} mountOnEnter unmountOnExit>
                    <Box data-testid="overlapping-primary">{primary}</Box>
                </Fade>
            </Box>
            <Box
                sx={{ position: 'absolute', right: 0, bottom: 0 }}
                ref={(element) => getRef(element, String(false))}
            >
                <Fade in={!showPrimary} mountOnEnter unmountOnExit>
                    <Box data-testid="overlapping-secondary">{secondary}</Box>
                </Fade>
            </Box>
        </Box>
    )
}

export default React.memo(OverlappingComponentsPair)
