import { Avatar, Tooltip } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    count: {
        defaultMessage:
            'You have {plantedCount, plural, one {# plant of this kind in the garden} other {# plants of this kind in the garden}}',
        description:
            'description of the current count of plants of this type the user has in their garden'
    }
})

export type PlantedCountProps = {
    plantedCount?: number
}

const PlantedCount: React.FC<PlantedCountProps> = ({ plantedCount }) => {
    const { palette, spacing } = useTheme()
    const { formatMessage } = useIntl()

    return (
        <>
            {plantedCount > 0 && (
                <Tooltip
                    title={
                        <CoreTypography variant="body1">
                            {formatMessage(messages.count, { plantedCount })}
                        </CoreTypography>
                    }
                >
                    <Avatar
                        sx={{
                            position: 'absolute',
                            top: spacing(-1.2),
                            right: spacing(-1.2),
                            background: palette.accent.main,
                            color: palette.info.light
                        }}
                        aria-hidden="true"
                        data-testid="social-avatar"
                        aria-label={formatMessage(messages.count, {
                            plantedCount
                        })}
                        alt=""
                    >
                        <CoreTypography variant="caption" fontSize="large">
                            {plantedCount}
                        </CoreTypography>
                    </Avatar>
                </Tooltip>
            )}
        </>
    )
}

export default memo(PlantedCount)
