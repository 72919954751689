import { useLocation } from 'react-router-dom'

export type LocationState = {
    referrer?: string
    from?: string
}

export type JourneySource = 'journey_home' | null

export function getJourneySourceFromLocationState(
    state: LocationState
): JourneySource {
    const { referrer, from } = state ?? {}

    const isJourneysReferrer = referrer?.toLowerCase().includes('journeys')
    const isFromJourneys = from?.toLowerCase().includes('journeys')

    if (isJourneysReferrer || isFromJourneys) {
        return 'journey_home'
    }

    return null
}

// It's just a wrapper to extract generic type from useLocation
// Don't use it directly
class UseLocationWrapper<T> {
    private useLocationWrapped = () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useLocation()
    }
}

type UseLocationResult<T> = ReturnType<
    UseLocationWrapper<T>['useLocationWrapped']
>

export function getJourneySourceFromCurrentLocation(
    location: UseLocationResult<LocationState>
): JourneySource {
    const { pathname } = location

    const isJourneysPath = pathname?.toLowerCase().includes('journeys')

    if (isJourneysPath) {
        return 'journey_home'
    }

    return null
}
