import { ComponentType, useEffect, useMemo, useState } from 'react'
import WaterProviderContext, {
    WaterProviderValue
} from './waterProviderContext'

export default function withWaterProvider<Props>(
    Component: ComponentType<Props>
) {
    return (props: Props): JSX.Element => {
        const [watering, setWatering] = useState(false)
        const [showEmptyingAnimation, setShowEmptyingAnimation] =
            useState(false)
        const [showRefillAnimation, setShowRefillAnimation] = useState(false)
        const [showJiggleAnimation, setShowJiggleAnimation] = useState(false)

        useEffect(() => {
            if (watering) {
                setShowEmptyingAnimation(true)
            }
        }, [watering])

        const state = useMemo<WaterProviderValue>(
            () => ({
                watering,
                showEmptyingAnimation,
                showRefillAnimation,
                showJiggleAnimation,
                setWatering,
                setShowEmptyingAnimation,
                setShowRefillAnimation,
                setShowJiggleAnimation
            }),
            [
                watering,
                showEmptyingAnimation,
                showRefillAnimation,
                showJiggleAnimation,
                setWatering,
                setShowEmptyingAnimation,
                setShowRefillAnimation,
                setShowJiggleAnimation
            ]
        )

        return (
            <WaterProviderContext.Provider value={state}>
                <Component {...props} />
            </WaterProviderContext.Provider>
        )
    }
}
