import { Card, Stack } from '@mui/material'
import {
    setGardenActionDisabled,
    setTokens
} from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafIcon,
    LoadingButton
} from '@thriveglobal/thrive-web-leafkit'
import React, {
    Suspense,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react'
import {
    FormattedMessage,
    FormattedNumber,
    defineMessages,
    useIntl
} from 'react-intl'
import { useDispatch } from 'react-redux'
import { ProductInfo } from '../../../../../graphql/generated/autogenerated'
import {
    usePlantIcons,
    usePlantsContext,
    useShopContext
} from '../../../../hooks'
import PlantedCount from './PlantedCount'

const messages = defineMessages({
    common: {
        defaultMessage: 'Common',
        description: 'product level common'
    },
    rare: {
        defaultMessage: 'Rare',
        description: 'product level rare'
    },
    grow: {
        defaultMessage: 'Grow the {plantName} plant',
        description: 'grow plant button text'
    }
})

export enum ProductLevel {
    COMMON = 'Common',
    RARE = 'Rare'
}

export type ProductCardProps = {
    product: ProductInfo
    level: ProductLevel
    tokens: number
    plantedCount?: number
    hasActivePlant: boolean
    onPurchase: (product: ProductInfo) => void
}

const ProductCard: React.FC<ProductCardProps> = ({
    product,
    level,
    tokens,
    plantedCount,
    hasActivePlant,
    onPurchase
}) => {
    const dispatch = useDispatch()
    const { getPlantProductIcon } = usePlantIcons()
    const { formatMessage } = useIntl()

    const [disablePurchase, setDisablePurchase] = useState<boolean>(true)
    const [insufficientFunds, setInsufficientFunds] = useState<boolean>(false)
    const [purchaseLoading, setPurchaseLoading] = useState<boolean>(false)
    const { purchaseProduct } = usePlantsContext()
    const { setHasActivePlant } = useShopContext()

    const initProps = useCallback(() => {
        if (!hasActivePlant && tokens >= product.cost) {
            setDisablePurchase(false)
        } else {
            setDisablePurchase(true)
        }
        if (tokens < product.cost && !hasActivePlant) {
            setInsufficientFunds(true)
        }
    }, [product.cost, tokens, hasActivePlant])

    useEffect(() => {
        initProps()
    }, [initProps])

    const onPurchaseHandler = useCallback(
        async (product: ProductInfo) => {
            try {
                setPurchaseLoading(true)
                await purchaseProduct(product)
                dispatch(setTokens(tokens - product.cost))
                onPurchase(product)
                setPurchaseLoading(false)
                setHasActivePlant(true)
            } catch (err) {
                dispatch(setGardenActionDisabled(false))
                setDisablePurchase(false)
                setPurchaseLoading(false)
                setHasActivePlant(false)
            }
        },
        [tokens, dispatch, onPurchase, purchaseProduct, setHasActivePlant]
    )

    const PlantIcon = useMemo(
        () => getPlantProductIcon(product),
        [getPlantProductIcon, product]
    )

    return (
        <Stack
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
            width="100%"
        >
            <Card
                elevation={1}
                data-testid="product-card"
                sx={{
                    position: 'relative',
                    overflow: 'visible',
                    width: '100%'
                }}
            >
                <Stack direction="row" p={2} gap={2}>
                    <Stack
                        width="100%"
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        gap={2}
                    >
                        <Suspense fallback={<></>}>
                            <PlantIcon
                                titleAccess={product.title}
                                sx={{
                                    width: 133,
                                    height: 133,
                                    mt: -5
                                }}
                            />
                        </Suspense>
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            onClick={() => onPurchaseHandler(product)}
                            data-testid="purchase-product-button"
                            startIcon={
                                disablePurchase && (
                                    <LeafIcon
                                        icon={'lock'}
                                        fontSize={'small'}
                                    />
                                )
                            }
                            disabled={disablePurchase || purchaseLoading}
                            loading={purchaseLoading}
                            aria-label={formatMessage(messages.grow, {
                                plantName: product.title
                            })}
                            fixWidth
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    defaultMessage="Grow this plant"
                                    description="button text that purchases a product"
                                />
                            </CoreTypography>
                        </LoadingButton>
                    </Stack>
                    <Stack width="65%" gap={1}>
                        <Stack flexDirection="column">
                            <CoreTypography variant="overline" component="h3">
                                <FormattedMessage
                                    defaultMessage="Level"
                                    description="text describing the steps to complete a product"
                                />
                            </CoreTypography>
                            <CoreTypography variant="h5" component="p">
                                {level === ProductLevel.COMMON
                                    ? formatMessage(messages.common)
                                    : level === ProductLevel.RARE
                                    ? formatMessage(messages.rare)
                                    : undefined}
                            </CoreTypography>
                        </Stack>
                        <Stack flexDirection="column">
                            <CoreTypography variant="overline" component="h3">
                                <FormattedMessage
                                    defaultMessage="Growth"
                                    description="text describing the days to complete a product"
                                />
                            </CoreTypography>
                            <CoreTypography variant="h5" component="p">
                                <FormattedMessage
                                    defaultMessage="{days} days"
                                    values={{
                                        days: product.plantWateringSteps
                                    }}
                                    description="text describing the days to complete a product"
                                />
                            </CoreTypography>
                        </Stack>
                        <Stack flexDirection="column">
                            <CoreTypography variant="overline" component="h3">
                                <FormattedMessage
                                    defaultMessage="Cost"
                                    description="text describing the cost of a product"
                                />
                            </CoreTypography>
                            <CoreTypography variant="h5" component="p">
                                <FormattedNumber value={product.cost} />
                            </CoreTypography>
                        </Stack>
                    </Stack>
                </Stack>
                <PlantedCount plantedCount={plantedCount} />
            </Card>
            {insufficientFunds && (
                <CoreTypography variant="body2" textAlign="start">
                    <FormattedMessage
                        defaultMessage="You don’t have enough tokens! Unlock more Achievements."
                        description="button text that purchases a product"
                    />
                </CoreTypography>
            )}
        </Stack>
    )
}

export default memo(ProductCard)
